import React, { useEffect, useState } from 'react';
import { Chip, ChipColor } from '../../../../components/Chip';
import { Card } from '../../../../components/Card';
import { ApplicationTagList } from '../../../../entities/v1/applicant_tracking/ApplicationTagList';
import { Application } from '../../../../entities/Application';
import { ApplicationTagService } from '../../../../services/v1/applicant_tracking/ApplicationTagService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { TagChip } from './TagChip';
import { ApplicationTag } from '../../../../entities/v1/applicant_tracking/ApplicationTag';
import { IconButton } from '../../../../components/IconButton';
import { AddTagModal } from './AddTagModal';
import { AlertObject } from '../../../../components/Alert';

interface PropTypes {
  application: Application;
  setAlert: (alert: AlertObject) => void;
}

export function TagsPanel(props: PropTypes) {
  const [applicationTags, setApplicationTags] = useState<ApplicationTag[]>([]);
  const [isApplicationTagsLoading, setIsApplicationTagsLoading] =
    useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    ApplicationTagService.list({
      applicationId: props.application.id,
      expand: ['tag'],
    })
      .then((response: ApplicationTagList) =>
        setApplicationTags(response.applicationTags),
      )
      .finally(() => setIsApplicationTagsLoading(false));
  }, []);

  function onDelete(id: number) {
    ApplicationTagService.delete(id).then(() => {
      setApplicationTags((prevTags) => prevTags.filter((tag) => tag.id !== id));
    });
  }

  function handleTagsAdded(newTags: ApplicationTag[]) {
    setApplicationTags((prevTags) => [...prevTags, ...newTags]);
  }

  return (
    <Card className='gap-3'>
      {isApplicationTagsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='d-flex'>
            <h3 className='mb-0 me-auto'>Tags</h3>

            <Chip color={ChipColor.BlueLight}>
              <i className='bi bi-tags me-1'></i>
              <span className='fs-6 fw-semibold'>{applicationTags.length}</span>
            </Chip>
          </div>
          <div className='d-flex flex-wrap gap-2'>
            {applicationTags.map((applicationTag) => (
              <div key={applicationTag.id}>
                <TagChip
                  applicationTag={applicationTag}
                  onDelete={onDelete}
                  upsertTagsEnabled={props.application.upsertTagsEnabled}
                />
              </div>
            ))}
          </div>
          {props.application.upsertTagsEnabled && (
            <>
              <hr className='mt-0 mb-0' />
              <div>
                <IconButton
                  iconName='bi-tag'
                  buttonText='Add Tag'
                  buttonClass='me-2h'
                  color='borderless'
                  onClick={() => setIsModalOpen(true)}
                />
              </div>
              {isModalOpen && (
                <AddTagModal
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
                  applicationId={props.application.id}
                  onTagsAdded={handleTagsAdded}
                  setAlert={props.setAlert}
                />
              )}
            </>
          )}
        </>
      )}
    </Card>
  );
}
