import React, { CSSProperties, Dispatch, useState } from 'react';
import classNames from 'classnames';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { editorConfig } from '../../config/editorConfig';
import { ToolbarPlugin } from '../../plugins/ToolbarPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { Size } from '../../../../utils/types/Size';
import styles from './styles.module.scss';
import { Placeholder } from '../Placeholder';
import { PluginLoader } from './PluginLoader';

interface PropTypes {
  disabled?: boolean;
  interactionDisabled?: boolean;
  defaultEditorState?: string;
  size: Size;
  placeholder?: string;
  externalPlugins?: JSX.Element[];
  onChangeJson?: (value: string) => void;
  onChangeHtml?: (value: string) => void;
}

const caretPaddingClassNames = 'py-2 px-3';

function getHeightStyle(size: Size): CSSProperties {
  if (size === 'xs') {
    return { minHeight: '180px', maxHeight: '180px' };
  }
  if (size === 'sm') {
    return { minHeight: `200px`, maxHeight: '200px' };
  }
  if (size === 'md') {
    return { minHeight: `250px`, maxHeight: '250px' };
  }

  return { minHeight: '500px', maxHeight: '500px' };
}

function Toolbar(props: {
  disabled?: boolean;
  size: Size;
  setIsLinkEditMode: Dispatch<boolean>;
}) {
  if (props.disabled) {
    return null;
  }

  return (
    <ToolbarPlugin
      setIsLinkEditMode={props.setIsLinkEditMode}
      hideAlignLabel={props.size === 'xs' || props.size === 'sm'}
      hideRedo={props.size === 'xs' || props.size === 'sm'}
      hideUndo={props.size === 'xs' || props.size === 'sm'}
      hideStyle={props.size === 'xs'}
      hideAlignment={props.size === 'xs'}
    />
  );
}

function AreaTextPlaceholder(props: { placeholder: string }) {
  return (
    <Placeholder
      className={classNames(
        styles['editor-placeholder'],
        'top-0',
        'start-0',
        caretPaddingClassNames,
      )}
      placeholder={props.placeholder}
    />
  );
}

export function AreaRichTextComposer(props: PropTypes) {
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  const onRef = (floatingAnchorElemParam: HTMLDivElement) => {
    if (floatingAnchorElemParam !== null) {
      setFloatingAnchorElem(floatingAnchorElemParam);
    }
  };

  return (
    <LexicalComposer
      initialConfig={{
        ...editorConfig,
        editable: !props.disabled && !props.interactionDisabled,
        editorState: props.defaultEditorState,
      }}
    >
      <Toolbar
        disabled={props.disabled}
        size={props.size}
        setIsLinkEditMode={setIsLinkEditMode}
      />
      <div
        className={classNames(
          props.disabled || props.interactionDisabled
            ? styles['editor-disabled']
            : styles['editor-container'],
          'm-0',
          'border',
        )}
      >
        <RichTextPlugin
          contentEditable={
            <div className='editor-scroller'>
              <div className='editor' ref={onRef}>
                <ContentEditable
                  style={getHeightStyle(props.size)}
                  className={classNames(
                    styles['editor-input'],
                    'overflow-auto',
                    caretPaddingClassNames,
                  )}
                />
              </div>
            </div>
          }
          placeholder={<AreaTextPlaceholder placeholder={props.placeholder} />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <PluginLoader
          disabled={props.disabled}
          externalPlugins={props.externalPlugins}
          onChangeJson={props.onChangeJson}
          onChangeHtml={props.onChangeHtml}
          floatingAnchorElem={floatingAnchorElem}
          isLinkEditMode={isLinkEditMode}
          setIsLinkEditMode={setIsLinkEditMode}
        />
      </div>
    </LexicalComposer>
  );
}
