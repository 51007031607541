import React, { useEffect, useState } from 'react';
import { capitalize } from 'humanize-plus';
import { Chip, ChipColor } from '../../../../../../../../components/Chip';
import { applicationStatusChipColor } from '../../../../../../../../utils/applicationStatusChipColor';
import {
  appendRedirectUri,
  removeTrailingSlashes,
} from '../../../../../../../../utils/url';
import { HrisEmployee } from '../../../../../../../../entities/applicant_tracking/HrisEmployees';
import { AvatarLink } from '../../../../../../../../components/AvatarLink';
import { getHrisLogo } from '../../../../../../../../utils/hris';
import { SpanTooltip } from '../../../../../../../../components/SpanTooltip';
import { Icon } from '../../../../../../../../components/Icon';
import {
  NewListCandidate,
  NewListCandidateApplication,
} from '../../../../../../../../entities/v1/applicant_tracking/NewListCandidate';
import { AnonymizedTooltip } from '../../../../../../../../components/AnonymizedTooltip';

interface PropTypes {
  candidate: NewListCandidate;
  hrisEmployee?: HrisEmployee;
  application: NewListCandidateApplication;
}

interface ApplicationBadgePropTypes {
  application: NewListCandidateApplication;
}

function Badge(props: { status: string; chipColor: ChipColor }) {
  return (
    <Chip className={'ms-2 fw-semibold'} color={props.chipColor}>
      {capitalize(props.status)}
    </Chip>
  );
}

function ApplicationBadge(props: ApplicationBadgePropTypes) {
  if (['archived', 'active'].includes(props.application.status)) return null;

  return (
    <Badge
      status={props.application.status}
      chipColor={applicationStatusChipColor(props.application.status)}
    />
  );
}

function NameWithLink(props: {
  application_id: number;
  name: string;
  disableLink?: boolean;
}) {
  if (props.disableLink) {
    return <span>{props.name}</span>;
  }

  const [candidateUrl, setCandidateUrl] = useState<string>('');

  function buildCandidateUrl() {
    return appendRedirectUri(
      `${removeTrailingSlashes(window.location.origin)}/applications/${
        props.application_id
      }`,
    );
  }

  useEffect(() => {
    setCandidateUrl(buildCandidateUrl());
  }, [window.location.href]);

  return (
    <a href={candidateUrl} className='text-truncate'>
      {props.name}
    </a>
  );
}

export function Name(props: PropTypes) {
  const hrisLogo = getHrisLogo(props.hrisEmployee?.provider);

  return (
    <div className='d-flex align-items-center fs-5'>
      <div className='fw-semibold text-truncate'>
        <div className='d-flex align-items-center'>
          {props.candidate.application != null ? (
            <>
              <NameWithLink
                application_id={props.candidate.application.id}
                name={props.candidate.displayName}
                disableLink={props.application.anonymized}
              />
              {props.application.anonymized && (
                <div className='col-auto px-1'>
                  <AnonymizedTooltip />
                </div>
              )}
              {props.candidate.redacted && (
                <SpanTooltip
                  className='ms-2'
                  text={
                    <Icon className='text-blue-200 fs-6' name='bi-file-lock2' />
                  }
                  tooltipText='Candidate information has been redacted to reduce bias.'
                />
              )}
              {hrisLogo && (
                <AvatarLink
                  name={hrisLogo}
                  type={'photo'}
                  size='sm'
                  url={props.hrisEmployee?.externalUrl}
                  className={'ms-2'}
                />
              )}
              <ApplicationBadge application={props.application} />
            </>
          ) : (
            <>
              <span className='text-truncate'>
                {props.candidate.displayName}
              </span>
              {props.candidate.anonymized && (
                <div className='col-auto px-1'>
                  <AnonymizedTooltip />
                </div>
              )}
              <Badge
                status={'draft'}
                chipColor={applicationStatusChipColor('draft')}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
