import {
  PipelineApplication,
  PipelineApplicationList,
} from '../../../entities/applicant_tracking/PipelineApplication';
import { ApiService } from '../../ApiService';
import snakecaseKeys from 'snakecase-keys';

export interface ListParams {
  jobId: number;
  stagePlanTemplateId?: number;
  page?: number;
  pageSize?: number;
  sortColumn?: string;
  sortDirection?: string;
}

export class PipelineApplicationService {
  public static list(params: ListParams): Promise<PipelineApplicationList> {
    const { jobId, ...optionalParams } = params;

    return ApiService.get<PipelineApplicationList>(
      `/api/v1/applicant_tracking/jobs/${jobId}/pipeline_applications`,
      snakecaseKeys(optionalParams),
    );
  }

  public static show(id: number): Promise<PipelineApplication> {
    return ApiService.get<PipelineApplication>(
      `/api/v1/applicant_tracking/pipeline_applications/${id}`,
    );
  }
}
