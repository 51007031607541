import React, { useEffect } from 'react';
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { FormFieldHeader } from '../../../../components/FormFieldHeader';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import classNames from 'classnames';
import { toIntOrNull } from '../../../../utils/toIntOrNull';
import { SelectFormHook } from '../../../../components/SelectFormHook';
import {
  AVAILABLE_CURRENCIES,
  DEFAULT_CURRENCY,
} from '../../../../utils/currency';
import { addSeparatorToSalary } from '../../../../utils/addSeparatorToSalary';
import { extractNumbers } from '../../../../utils/extractors/extractNumbers';

interface PropTypes {
  salaryInputColSize?: number;
  inputGroupClassNames?: string;
  containerClassNames?: string;
  formHeaderClassNames?: string;
  formControl: Control<CandidateDto>;
  errors: FieldErrors;
  disabled?: boolean;
  shouldRender: boolean;
  required: boolean;
  watch: UseFormWatch<CandidateDto>;
  formResetField: (controllerName: string) => void;
  formRegister: UseFormRegister<CandidateDto>;
  getValues: UseFormGetValues<any>;
  setValue?: UseFormSetValue<any>;
  width?: number;
  label?: string;
}

interface SalaryCurrencyPropTypes extends PropTypes {
  required: boolean;
}

function SalaryAndCurrency(props: SalaryCurrencyPropTypes) {
  const salaryExpectationFieldName = 'salaryExpectation';
  const disabled = props.disabled ?? false;

  useEffect(() => {
    const value = props.getValues(salaryExpectationFieldName)?.toString();

    props.setValue(salaryExpectationFieldName, addSeparatorToSalary(value));
  }, []);

  return (
    <Row className={classNames(props.inputGroupClassNames, 'gx-3')}>
      <Col xs={props.salaryInputColSize || 6}>
        <input
          className={classNames('form-control', 'fs-5', {
            'is-invalid': props.errors.salaryExpectation,
          })}
          type={'text'}
          inputMode={'numeric'}
          placeholder='e.g. 12,000'
          {...props.formRegister(salaryExpectationFieldName, {
            onChange: (e) => {
              props.setValue(
                salaryExpectationFieldName,
                addSeparatorToSalary(e.target.value),
              );
            },
            setValueAs: (v) => toIntOrNull(extractNumbers(v?.toString())),
            required: props.required,
            pattern: {
              value: /^([1-9][0-9]{0,2})((,[0-9]{3})*|[0-9]*)([.][0-9]{2})?$/,
              message: 'Salary must be a number',
            },
          })}
          disabled={disabled}
        />
        {props.errors?.salaryExpectation?.message && (
          <small className='text-danger fw-normal'>
            {props.errors?.salaryExpectation?.message}
          </small>
        )}
      </Col>
      <Col xs={props.salaryInputColSize || 6}>
        <SelectFormHook<CandidateDto>
          placeholder={'Select currency'}
          formHookContext={{
            controllerName: 'salaryCurrency',
            formControl: props.formControl,
            required: props.required,
          }}
          options={AVAILABLE_CURRENCIES}
          formResetField={props.formResetField}
          disabled={props.disabled}
          clearable={false}
          defaultValue={DEFAULT_CURRENCY.value}
          autoHideKeyboardOnSmallerScreen={true}
        />
      </Col>
    </Row>
  );
}

export function ApplicationSalaryInput(props: PropTypes) {
  if (!props.shouldRender) return null;

  const label = props.label ?? 'Expected Annual Salary';

  return (
    <Row className={classNames(props.containerClassNames)}>
      <Col xs={props.width}>
        <FormFieldHeader
          wrapText
          fieldName={label}
          isRequired={props.required}
          classNames={classNames(props.formHeaderClassNames)}
        />
        <SalaryAndCurrency {...props} label={label} />
      </Col>
    </Row>
  );
}
