import React from 'react';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { Input } from 'reactstrap';
import { Select, SelectOption } from '../../../../../../../components/Select';
import {
  AVAILABLE_CURRENCIES,
  DEFAULT_CURRENCY,
} from '../../../../../../../utils/currency';

interface PropTypes {
  currency?: string;
  salary?: number;
  setCurrency: (currency: string) => void;
  setSalary: (salary: number) => void;
}

export function SalaryInput(props: PropTypes) {
  const selected =
    AVAILABLE_CURRENCIES.find((c) => c.value === props.currency) ||
    DEFAULT_CURRENCY;

  return (
    <>
      <FormFieldHeader fieldName={'Salary'} isRequired={true} />
      <div className='d-flex flex-nowrap gap-3'>
        <Input
          type='number'
          className='fs-5 w-50'
          placeholder='(e.g. 50000)'
          defaultValue={props.salary}
          onChange={(event) => {
            props.setSalary(event.target.value as unknown as number);
          }}
        />
        <Select
          placeholder={'Select currency'}
          options={AVAILABLE_CURRENCIES}
          onChange={(option: SelectOption) => props.setCurrency(option.value)}
          selected={{
            value: selected.value,
            label: selected.label,
          }}
          isClearable={false}
          className='w-50'
        />
      </div>
    </>
  );
}
