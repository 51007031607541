import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { PipelineJobStageList } from '../../../../entities/v1/applicant_tracking/PipelineJobStage';

export class PipelineJobStagesService {
  public static list(jobId: number): Promise<PipelineJobStageList> {
    return ApiService.get<PipelineJobStageList>(
      `/api/v1/applicant_tracking/jobs/${jobId}/pipeline_job_stages`,
      snakecaseKeys({
        jobId,
        expand: ['job_stage'],
      }),
    );
  }
}
