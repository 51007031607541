import React, { useRef, useState } from 'react';
import { isPDFUrl } from '../../utils/pdfHandler';
import { Document, Page, pdfjs } from 'react-pdf';
import { LoadingSpinner } from '../LoadingSpinner';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ExternalLinkTarget } from 'react-pdf/dist/cjs/shared/types';

interface PropTypes {
  url: string;
  width?: number;
  loadingElement?: JSX.Element;
  externalLinkTarget?: ExternalLinkTarget;
}

pdfjs.GlobalWorkerOptions.workerSrc = '/static/pdf_worker.min.js';

export function PDFViewer(props: PropTypes) {
  if (!props.url || !isPDFUrl(props.url)) {
    return null;
  }

  const containerRef = useRef(null);
  const [numPages, setNumPages] = useState<number>();
  const [width, setWidth] = useState<number>();

  const onResize = () => {
    containerRef.current && setWidth(containerRef.current.clientWidth);
  };
  const resizeObserver = new ResizeObserver(onResize);
  containerRef.current && resizeObserver.observe(containerRef.current);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  return (
    <div ref={containerRef}>
      <Document
        file={props.url}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={props.loadingElement || <LoadingSpinner />}
        externalLinkTarget={props.externalLinkTarget}
      >
        {Array.from(new Array(numPages), (_el, index) => (
          <Page
            key={index}
            pageNumber={index + 1}
            width={props.width ?? width}
            loading={null}
          />
        ))}
      </Document>
    </div>
  );
}
