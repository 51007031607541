import { get, KnownKeys, pop, set } from '../localArchive';
import { appendRedirectUri, getQueryParam } from '../url';
import {
  BulkSelfSchedulingDto,
  BulkSelfSchedulingType,
} from '../../services/dtos/BulkSelfSchedulingDto';

export const SESSION_UUID_PARAM = 'session_uuid';

export function redirectToBulkSelfSchedulingPage(
  type: BulkSelfSchedulingType,
  jobId: number,
  interviewStageId: number,
  totalApplications: number,
  applicationIds?: number[],
) {
  const sessionUUID = crypto.randomUUID();

  set(
    KnownKeys.BulkSelfScheduling,
    {
      applicationIds: applicationIds,
      totalApplications: totalApplications,
      type: type,
    },
    sessionUUID,
  );

  window.location.href = appendRedirectUri(
    `/applicant_tracking/interview_candidate_users/bulk_new?job_id=${jobId}&interview_stage_id=${interviewStageId}&${SESSION_UUID_PARAM}=${sessionUUID}`,
  );
}

export function getSessionUUID(): string {
  return getQueryParam(SESSION_UUID_PARAM);
}

export function getSessionSelfSchedulingDto(): BulkSelfSchedulingDto {
  return get<BulkSelfSchedulingDto>(
    KnownKeys.BulkSelfScheduling,
    getSessionUUID(),
  );
}

export function setSessionSelfSchedulingDto(dto: BulkSelfSchedulingDto): void {
  set<BulkSelfSchedulingDto>(
    KnownKeys.BulkSelfScheduling,
    dto,
    getSessionUUID(),
  );
}

export function popSessionSelfSchedulingDto(): BulkSelfSchedulingDto {
  return pop(KnownKeys.BulkSelfScheduling, getSessionUUID());
}

export function isDtoValid(dto: BulkSelfSchedulingDto): boolean {
  if (dto == null || dto.type == null) {
    return false;
  }

  if (
    dto.type === BulkSelfSchedulingType.Applications &&
    (dto.applicationIds == null || dto.applicationIds.length === 0)
  ) {
    return false;
  }

  if (
    dto.type == BulkSelfSchedulingType.JobStage &&
    dto.applicationIds?.length > 0
  ) {
    return false;
  }

  return true;
}
