import { useEffect, useState } from 'react';
import { SpanTooltip } from '../SpanTooltip';
import { IconButton } from '../IconButton';
import React from 'react';
import { Size } from '../../utils/types/Size';
import { HorizontalPlacement } from '../../utils/types/Placement';

interface PropTypes {
  textToBeCopied: string;
  tooltipTextOnClick?: string;
  tooltipTextOnHover?: string;
  hideText?: boolean;
  position?: HorizontalPlacement;
  text?: string;
  size?: Size;
  buttonClass?: string;
  color?: string;
  disabled?: boolean;
}

const DEFAULT_TOOLTIP_TEXT_ON_HOVER = 'Copy';
const DEFAULT_TOOLTIP_TEXT_ON_CLICK = 'Copied!';

function buttonText(hideText: boolean, text?: string) {
  if (hideText == true) return null;

  return text ?? 'Copy';
}

export function CopyButton(props: PropTypes) {
  const [isCopied, setIsCopied] = useState(false);
  const hideText = props.hideText ?? false;

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  }, [isCopied]);

  function handleCopyButtonClick() {
    navigator.clipboard.writeText(props.textToBeCopied);

    setIsCopied(true);
  }

  const tooltipTextOnClick =
    props.tooltipTextOnClick ?? DEFAULT_TOOLTIP_TEXT_ON_CLICK;
  const tooltipTextOnHover =
    props.tooltipTextOnHover ?? DEFAULT_TOOLTIP_TEXT_ON_HOVER;
  const buttonSize = props.size ?? 'sm';

  return (
    <SpanTooltip
      text={
        <IconButton
          iconName='bi-files'
          color={props.color ?? 'borderless'}
          size={buttonSize}
          onClick={handleCopyButtonClick}
          position={props.position ?? 'end'}
          buttonText={buttonText(hideText, props.text)}
          buttonClass={props.buttonClass}
          disabled={props.disabled}
        />
      }
      tooltipText={isCopied ? tooltipTextOnClick : tooltipTextOnHover}
      trigger='hover'
    />
  );
}
