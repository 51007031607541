import { DateRange } from '../components/DateRangePicker';
import { replaceAll } from './replaceAll';

export function stringToDateRange(label: string): DateRange {
  switch (replaceAll(label.toLowerCase(), ' ', '_')) {
    case 'today':
      return DateRange.Today;
    case 'yesterday':
      return DateRange.Yesterday;
    case 'last_7_days':
      return DateRange.Last7Days;
    case 'month_to_date':
      return DateRange.MonthToDate;
    case 'last_month':
      return DateRange.LastMonth;
    case 'last_30_days':
      return DateRange.Last30Days;
    case 'last_90_days':
      return DateRange.Last90Days;
    default:
      return DateRange.CustomRange;
  }
}
