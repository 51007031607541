import React from 'react';
import womanSchedulingImg from '../../../../../images/woman-scheduling.png';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { User } from '../../../../../entities/User';
import { Candidate } from '../../../../../entities/applicant_tracking/Candidate';
import { InterviewParticipants } from './InterviewParticipants';
import { VcsProvider } from '../../../../../enums/IntegrationProvider/VcsProvider';
import { InterviewerParticipant } from '../../../../../entities/applicant_tracking/InterviewerParticipant';
import { InterviewDuration } from './InterviewDuration';
import { InterviewMeetingProvider } from './InterviewMeetingProvider';

interface PropTypes {
  title: string;
  applicationIds?: number[];
  totalApplications?: number;
  candidate?: Candidate;
  participants: InterviewerParticipant[];
  setParticipants: (participants: InterviewerParticipant[]) => void;
  availableParticipants: User[];
  durationMinutes: number;
  setDurationMinutes: (duration: number) => void;
  meetingProvider: VcsProvider;
  setMeetingProvider: (provider: VcsProvider) => void;
  meetingProviders: VcsProvider[];
  customImage?: string;
  description?: string;
  candidateView: boolean;
  onChangingMeetingOrganizer: (participant: InterviewerParticipant) => void;
  allowChangingMeetingOrganizer: boolean;
  allowIgnoreParticipantCalendar: boolean;
}

function Description(props: { description: string }) {
  if (props.description == null) {
    return null;
  }

  return (
    <div className={classNames('d-flex justify-content-center')}>
      <div
        className={classNames(
          'text-center fs-6 pt-1 text-muted',
          styles['description-text'],
        )}
      >
        {props.description}
      </div>
    </div>
  );
}

export function InterviewDetails(props: PropTypes) {
  const totalApplications =
    props.applicationIds?.length || props.totalApplications || 0;

  return (
    <>
      <div className={'text-center'}>
        <img
          src={props.customImage || womanSchedulingImg}
          style={{ maxWidth: '250px' }}
        />
      </div>
      <h2 className={'mt-4 text-center'}>{props.title}</h2>
      <Description description={props.description} />
      <div
        className={classNames(
          'd-flex',
          'flex-column',
          'overflow-scroll',
          styles['details'],
        )}
      >
        <InterviewParticipants
          {...props}
          totalApplications={totalApplications}
        />
        <InterviewDuration
          durationMinutes={props.durationMinutes}
          candidateView={props.candidateView}
          setDurationMinutes={props.setDurationMinutes}
          className={'mt-4 w-60'}
        />
        <InterviewMeetingProvider
          meetingProvider={props.meetingProvider}
          setMeetingProvider={props.setMeetingProvider}
          candidateView={props.candidateView}
          meetingProviders={props.meetingProviders}
          className={'mt-3 w-60'}
        />
      </div>
    </>
  );
}
