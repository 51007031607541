import React from 'react';
import parse from 'html-react-parser';
import { Card } from '../../../../components/Card';
import { AlertMessage } from '../../../../components/AlertMessage';
import { get, KnownKeys } from '../../../../utils/localArchive';
import { UpsertOfferLetterTemplateDto } from '../../OfferLetterTemplates/components/UpsertEmailTemplateDto';
import { EmptyState } from '../../../../components/EmptyState';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { OfferActions } from './OfferActions';
import { domPurify, updateLinksToNewTab } from '../../../../utils/dom';

interface PropTypes {
  status: string;
  organizationLogoUrl?: string;
  offerLetterHtml?: string;
  id?: string;
  rejectUrl?: string;
  acceptUrl?: string;
}

function Header(props: {
  previewMode: boolean;
  organizationLogoUrl?: string;
  name?: string;
}) {
  if (props.organizationLogoUrl != null) {
    return (
      <img
        className={classNames('mb-6h', styles.logo)}
        src={props.organizationLogoUrl}
      />
    );
  } else if (props.name != null) {
    return (
      <h1 className='mb-5'>
        {props.name || 'N/A'}
        {' Offer'}
        {props.previewMode && ' preview'}
      </h1>
    );
  }

  return null;
}

function StatusMessage(props: { id: string; status: string }) {
  if (props.id != null && props.status === 'pending') {
    return null;
  }

  let colour = 'bg-light-warning';
  let message = 'This is an Offer letter preview';

  switch (props.status) {
    case 'revoked':
      colour = 'bg-light-warning';
      message = 'This Offer is no longer available.';
      break;
    case 'accepted':
      colour = 'bg-light-success';
      message = 'This Offer was already accepted.';
      break;
    case 'rejected':
      colour = 'bg-light-danger';
      message = 'This Offer was already declined.';
      break;
    case 'pending':
      colour = 'bg-light-info';
      message = 'This Offer is waiting to be actioned.';
      break;
  }

  return (
    <AlertMessage
      className={classNames('mx-auto my-4 fs-5', colour)}
      icon={{ name: 'bi-info-circle' }}
      text={message}
      hasOverlay={false}
    />
  );
}

export default function OfferLetterPage(props: PropTypes) {
  const offerLetterTemplateStored = get<UpsertOfferLetterTemplateDto>(
    KnownKeys.OfferLetterTemplate,
  );
  const html = props.offerLetterHtml || offerLetterTemplateStored?.templateHtml;

  if (html == null) {
    return (
      <EmptyState
        cardless
        title={'It is not possible to preview this offer.'}
      />
    );
  }

  return (
    <div className={classNames('vh-100', { 'm-5': !props.id })}>
      <StatusMessage id={props.id} status={props.status} />
      <Card
        className={classNames(
          'h-90',
          'mx-auto',
          'p-6h',
          styles['offer-container'],
        )}
      >
        <Header
          name={offerLetterTemplateStored?.name}
          organizationLogoUrl={props.organizationLogoUrl}
          previewMode={!props.id}
        />
        {parse(domPurify(updateLinksToNewTab(html)))}
        <OfferActions
          id={props.id}
          disabled={props.status != 'pending'}
          rejectUrl={props.rejectUrl}
          acceptUrl={props.acceptUrl}
        />
      </Card>
    </div>
  );
}
