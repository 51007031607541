import React, { Fragment, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { DeprecatedPanel } from '../../../components/DeprecatedPanel';
import { Survey } from '../../../reference_check/entities/Survey';
import { SurveyModuleVersion } from '../../../reference_check/entities/SurveyModuleVersion';
import { SurveyQuestion } from '../../../reference_check/entities/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../../reference_check/entities/SurveyQuestionAnswer';
import { SurveyQuestionFieldAnswer } from '../../../reference_check/entities/SurveyQuestionFieldAnswer';
import { SurveyRequest } from '../../../reference_check/entities/SurveyRequest';
import { OverallScoreCard } from './OverallScoreCard';
import { NegativeSummaryScoreCard } from './NegativeSummaryScoreCard';
import { RankResultsTable } from './RankResultsTable';
import { RatingResultsTable } from './RatingResultsTable';
import { TextResultsTable } from './TextResultsTable';
import { PositiveSummaryScoreCard } from './PositiveSummaryScoreCard';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { findReferralSurveys } from '../../../reference_check/utils/findReferralSurveys';
import { findCandidateSurvey } from '../../../reference_check/utils/findCandidateSurvey';
import { getAnsweredQuestionAnswers } from '../../../reference_check/utils/getAnsweredQuestionAnswers';
import {
  handlePDFPageBreak,
  REPORTING_PAGE_BREAK_CLASS,
  REPORTING_SECTION_CLASS,
} from '../../../utils/pdfHandler';
import { EmptyState } from '../../../components/EmptyState';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { ReferenceRequestStatus } from '../ReferenceRequestStatus';

interface PropTypes {
  surveyRequest: SurveyRequest;
  referenceRequestStatus: ReferenceRequestStatus;
  exportToPDF?: boolean;
}

const renderRatingResult = (
  surveys: Survey[],
  questions: SurveyQuestion[],
  answers: SurveyQuestionAnswer[],
  exportToPDF: boolean,
) => {
  return (
    <RatingResultsTable
      key={`RatingResultsTable${questions[0].id}`}
      surveys={surveys}
      questions={questions}
      answers={answers}
      exportToPDF={exportToPDF}
    />
  );
};

const renderRankingResult = (
  question: SurveyQuestion,
  referralAnswers: SurveyQuestionAnswer[],
  candidateAnswers: SurveyQuestionAnswer[],
  exportToPDF: boolean,
) => {
  const questionField = question.fields[0];
  const referralFieldAnswers = referralAnswers
    .reduce<SurveyQuestionFieldAnswer[]>((res, a) => res.concat(a.fields), [])
    .filter((f) => f.field_id === questionField.id);
  const candidateFieldAnswers = candidateAnswers
    .reduce<SurveyQuestionFieldAnswer[]>((res, a) => res.concat(a.fields), [])
    .filter((f) => f.field_id === questionField.id);
  return (
    <RankResultsTable
      key={`RankResultsTable${question.id}`}
      question={question}
      isPositive={questionField.positive}
      questionField={questionField}
      referralAnswers={referralFieldAnswers}
      candidateAnswers={candidateFieldAnswers}
      exportToPDF={exportToPDF}
    />
  );
};

const renderTextResult = (
  surveys: Survey[],
  question: SurveyQuestion,
  answers: SurveyQuestionAnswer[],
  exportToPDF: boolean,
) => {
  return question.fields.map((field) => {
    const fieldAnswers = answers.filter(
      (a) => a.fields[0].field_id === field.id,
    );

    return (
      <TextResultsTable
        key={`TextResultsTable${field.id}`}
        question={question}
        surveys={surveys}
        questionField={field}
        answers={fieldAnswers}
        exportToPDF={exportToPDF}
      />
    );
  });
};

const renderResults = (
  surveys: Survey[],
  questions: SurveyQuestion[],
  exportToPDF: boolean,
  referralAnswers: SurveyQuestionAnswer[],
  candidateAnswers?: SurveyQuestionAnswer[],
) => {
  const field = questions[0].fields[0];
  const referralQuestionAnswers = referralAnswers.filter(
    (a) => questions.findIndex((q) => q.id === a.question_id) !== -1,
  );
  const candidateQuestionAnswers =
    candidateAnswers?.filter(
      (a) => questions.findIndex((q) => q.id === a.question_id) !== -1,
    ) || [];
  const allQuestionAnswers = referralQuestionAnswers.concat(
    candidateQuestionAnswers,
  );

  if (field.type === 'multi_options') {
    return renderRankingResult(
      questions[0],
      referralQuestionAnswers,
      candidateQuestionAnswers,
      exportToPDF,
    );
  }
  if (field.type === 'text') {
    return renderTextResult(
      surveys,
      questions[0],
      referralQuestionAnswers,
      exportToPDF,
    );
  }
  if (isRating(questions[0], allQuestionAnswers)) {
    return renderRatingResult(
      surveys,
      questions,
      allQuestionAnswers,
      exportToPDF,
    );
  }
};

const isRating = (
  question: SurveyQuestion,
  answers: SurveyQuestionAnswer[],
): boolean => {
  if (question.fields[0].type === 'rating') return true;
  if (question.fields[0].type === 'text') return false;

  return answers
    .reduce<SurveyQuestionFieldAnswer[]>((res, a) => res.concat(a.fields), [])
    .filter((ans) => ans.field_id === question.fields[0].id)
    .map((f) => f.value != null)
    .every((r) => r === true);
};

const groupSingleRatingQuestions = (
  module: SurveyModuleVersion,
  answers: SurveyQuestionAnswer[],
) => {
  return module.questions.filter((q) => {
    if (q.fields.length !== 1) return false;

    return isRating(q, answers);
  });
};

const getStandaloneQuestions = (
  module: SurveyModuleVersion,
  answers: SurveyQuestionAnswer[],
) => {
  const groupedIds = groupSingleRatingQuestions(module, answers).map(
    (q) => q.id,
  );
  return module.questions.filter((q) => groupedIds.indexOf(q.id) === -1);
};

const renderGroupedRatingQuestions = (
  surveys: Survey[],
  module: SurveyModuleVersion,
  answers: SurveyQuestionAnswer[],
  exportToPDF: boolean,
) => {
  surveys = findReferralSurveys(surveys);
  if (surveys.length === 0) return null;

  const group = groupSingleRatingQuestions(module, answers);
  if (group.length === 0) return null;

  return (
    <Col xs='12'>
      <DeprecatedPanel additionalInnerContainerClassNames='p-4'>
        <Row>
          <Col>
            <h2>{module.module_name}</h2>
          </Col>
        </Row>
        <Row>
          <Col>{renderResults(surveys, group, exportToPDF, answers)}</Col>
        </Row>
      </DeprecatedPanel>
    </Col>
  );
};

export default React.memo(function SuccessTab({
  surveyRequest,
  referenceRequestStatus,
  exportToPDF,
}: PropTypes) {
  if (exportToPDF === undefined) {
    exportToPDF = false;
  }

  const surveys =
    surveyRequest == null
      ? []
      : surveyRequest.referrals
          .map((referral) => referral.surveys[0])
          .filter((s) => s != null && s.status === 'completed');

  if (referenceRequestStatus === 'loading') {
    return <LoadingSpinner />;
  }

  if (surveys.length === 0) {
    return (
      <EmptyState
        title='No feedback is available yet'
        text={
          <p>
            Start the feedback request process and results will be displayed
            here once the first feedback is submitted.
          </p>
        }
      />
    );
  }

  const referralAnswers = findReferralSurveys(surveys).reduce<
    SurveyQuestionAnswer[]
  >((prev, s) => prev.concat(s.answers), []);
  const candidateAnswers = findCandidateSurvey(surveys)?.answers || [];
  const allAnswers = getAnsweredQuestionAnswers(
    referralAnswers.concat(candidateAnswers),
  );

  useEffect(() => {
    if (exportToPDF) {
      handlePDFPageBreak();
    }
  });

  return (
    <>
      <TopRow
        surveyRequest={surveyRequest}
        allAnswers={allAnswers}
        exportToPDF={exportToPDF}
      />
      {surveyRequest.survey_request_survey_module_versions
        .sort((a, b) => a.order - b.order)
        .map(
          (survey_request_survey_module_version) =>
            survey_request_survey_module_version.survey_module_version,
        )
        .map((mod) => (
          <Fragment key={mod.id}>
            {exportToPDF && <PageBreak />}
            <div className={classNames(REPORTING_SECTION_CLASS)}>
              {renderGroupedRatingQuestions(
                surveys,
                mod,
                referralAnswers,
                exportToPDF,
              )}
              <Row className={classNames(styles.pdf, 'mt-4')}>
                {getStandaloneQuestions(mod, allAnswers).map((q) => {
                  return renderResults(
                    surveys,
                    [q],
                    exportToPDF,
                    referralAnswers,
                    candidateAnswers,
                  );
                })}
              </Row>
            </div>
          </Fragment>
        ))}
    </>
  );
});

function TopRow(props: {
  surveyRequest: SurveyRequest;
  allAnswers: SurveyQuestionAnswer[];
  exportToPDF: boolean;
}) {
  if (props.allAnswers.length === 0) return null;

  const surveyModuleVersions =
    props.surveyRequest.survey_request_survey_module_versions.map(
      (srsmv) => srsmv.survey_module_version,
    );

  return (
    <Row className={classNames('mb-4', REPORTING_SECTION_CLASS)}>
      <Col lg='3'>
        <OverallScoreCard
          className='h-100'
          surveyRequest={props.surveyRequest}
          answers={props.allAnswers}
        />
      </Col>
      <Col>
        <Row>
          <Col lg='6'>
            <PositiveSummaryScoreCard
              className='h-100'
              surveyModuleVersions={surveyModuleVersions}
              answers={props.allAnswers}
              exportToPDF={props.exportToPDF}
            />
          </Col>
          <Col lg='6'>
            <NegativeSummaryScoreCard
              className='h-100'
              surveyModuleVersions={surveyModuleVersions}
              answers={props.allAnswers}
              exportToPDF={props.exportToPDF}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function PageBreak() {
  return <div className={classNames(REPORTING_PAGE_BREAK_CLASS)} />;
}
