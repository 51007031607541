import React from 'react';
import { Scorecard } from '../../../../../../entities/applicant_tracking/Scorecard';
import { updateSurveyAnswers } from '../../../../../../utils/applicant_tracking/scorecardSurvey';
import { ScorecardTextQuestion } from './ScorecardTextQuestion';
import { Section } from '../../../../../../entities/v1/survey_engine/Section';
import { Question } from '../../../../../../entities/v1/survey_engine/Question';
import { getAnswerToQuestion } from '../../../../../../utils/survey_engine/getAnswerToQuestion';
import { Col } from 'reactstrap';

interface PropTypes {
  scorecard: Scorecard;
  section: Section;
  aiScorecardFill?: boolean;
  readonly?: boolean;
  setScorecard?: (
    value: Scorecard | ((prevScorecard: Scorecard) => Scorecard),
  ) => void;
  disabled: boolean;
}

interface TextBlockPropTypes extends PropTypes {
  question: Question;
  title: string;
}

function ReadOnlyTextBlock(props: TextBlockPropTypes) {
  const answer = getAnswerToQuestion(props.scorecard.survey, props.question);

  return (
    <Col xs='12' className='align-items-stretch mt-4'>
      <div className='fs-5 fw-semibold text-primary-dark mb-2'>
        {props.title}
      </div>
      <div
        className='p-2h bg-gray rounded-2 flex-fill fs-5 text-dark-500 mt-1 text-dark-500'
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {answer ? (
          answer
        ) : (
          <span className='text-dark-200'>No comments were added.</span>
        )}
      </div>
    </Col>
  );
}

function InputTextBlock(props: TextBlockPropTypes) {
  const onUpdate = (attribute: Question, value: string) => {
    props.setScorecard?.((scorecard: Scorecard) => ({
      ...scorecard,
      survey: updateSurveyAnswers(scorecard.survey, attribute, true, value),
    }));
  };

  return (
    <ScorecardTextQuestion
      key={props.question.id}
      title={<span className='fs-5 fw-semibold'>{props.title}</span>}
      label='Insert response here'
      aiScorecardFill={props.aiScorecardFill && props.question.aiFillEnabled}
      attribute={props.title}
      scorecard={props.scorecard}
      value={getAnswerToQuestion(props.scorecard.survey, props.question)}
      onUpdate={(value) => onUpdate(props.question, value)}
      disabled={props.disabled}
    />
  );
}

export function OpenTextSection(props: PropTypes) {
  return (
    <>
      <div className='fw-semibold fs-3 mt-5h'>{props.section.name}</div>
      {props.section.questions.map((question: Question) => {
        return props.readonly ? (
          <ReadOnlyTextBlock
            question={question}
            key={question.id}
            title={question.title}
            {...props}
          />
        ) : (
          <InputTextBlock
            question={question}
            key={question.id}
            title={question.title}
            {...props}
          />
        );
      })}
    </>
  );
}
