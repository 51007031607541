import React, { useEffect, useRef, useState } from 'react';
import {
  AlertMessage,
  ListApplicationsPageHeader,
} from './ListApplicationsPageHeader';
import { Job } from '../../../../entities/applicant_tracking/Job';
import { JobStageTemplate } from '../../../../entities/applicant_tracking/JobStageTemplate';
import ListPageEmptyState from '../../ListPageEmptyState';
import { User } from '../../../../entities/User';
import { Alert, AlertObject } from '../../../../components/Alert';
import { HrisEmployeesService } from '../../../../services/applicant_tracking/HrisEmployeesService';
import {
  HrisEmployee,
  HrisEmployees,
} from '../../../../entities/applicant_tracking/HrisEmployees';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';
import { EmailAccountIntegration } from '../../../../entities/EmailAccountIntegration';
import { ListApplicationsPageBody } from './ListApplicationsPageBody';
import {
  ApplicationListingFilters,
  DEFAULT_FILTER_STATE,
  FilterType,
  resetIfFilterNotSelected,
  resetPageIfFiltersChanged,
} from './ListApplicationsPageBody/ListApplicationsFilters/utils';
import {
  NewListCandidate,
  NewListCandidates,
} from '../../../../entities/v1/applicant_tracking/NewListCandidate';
import { ApplicationService } from '../../../../services/v1/applicant_tracking/ApplicationService';
import { getQueryParam } from '../../../../utils/url';
import { SEARCH_PARAM } from './OpenSearch';

interface PropTypes {
  alertMessage: AlertMessage;
  currentUser: User;
  emailAccountIntegration: EmailAccountIntegration;
  hrisProvider: HrisProvider;
  jobs: Job[];
  jobStages: JobStageTemplate[];
  policies: CandidatePolicies;
  totalCandidates: number;
}

export interface CandidatePolicies {
  addCandidateEnabled: boolean;
}

export default function ListApplicationsPage(props: PropTypes) {
  const requestsCount = useRef(0);
  const isInitialMount = useRef(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedCandidates, setSelectedCandidates] = useState<
    NewListCandidate[]
  >([]);
  const [totalFilteredCandidates, setTotalFilteredCandidates] =
    useState<number>(0);
  const [candidates, setCandidates] = useState<NewListCandidate[]>([]);
  const [hrisEmployees, setHrisEmployees] = useState<HrisEmployee[]>([]);
  const [filterStates, setFilterStates] =
    useState<ApplicationListingFilters>(DEFAULT_FILTER_STATE);
  const [previousFilterStates, setPreviousFilterStates] =
    useState<ApplicationListingFilters>(DEFAULT_FILTER_STATE);
  const [filterTypes, setFilterTypes] = useState<FilterType[]>([]);
  const [alert, setAlert] = useState<AlertObject>(null);
  const [error, setError] = useState(false);

  const loadCandidates = () => {
    const currentRequestCount = ++requestsCount.current;
    setIsLoading(true);

    ApplicationService.search(filterStates, getQueryParam(SEARCH_PARAM))
      .then((response: NewListCandidates) => {
        const { candidates, entriesCount } = response;
        const applicationIds = candidates.map((c) => c.application?.id);

        setError(false);

        if (currentRequestCount != requestsCount.current) return;

        setCandidates(candidates);
        setTotalFilteredCandidates(entriesCount);

        applicationIds.length > 0 &&
          HrisEmployeesService.list({ applicationIds }).then(
            (response: HrisEmployees) => {
              setHrisEmployees(response.employees);
            },
          );
      })
      .catch((_e) => setError(true))
      .finally(() => setIsLoading(false));
  };

  const performSearch = () => {
    resetPageIfFiltersChanged(
      previousFilterStates,
      filterStates,
      setFilterStates,
    );
    setPreviousFilterStates(filterStates);
    loadCandidates();
    setSelectedCandidates([]);
  };

  const reloadPage = () => {
    setSelectedCandidates([]);
    loadCandidates();
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      // Only skips the first search if the search parameter is empty.
      if (!getQueryParam(SEARCH_PARAM)) {
        return;
      }
    }

    performSearch();
  }, [filterStates.sortingColumn, filterStates.page]);

  useEffect(
    () => resetIfFilterNotSelected(filterTypes, setFilterStates),
    [filterTypes],
  );

  return props.totalCandidates === 0 ? (
    <ListPageEmptyState
      title={'No candidates added yet'}
      text={'Create your first candidate to start using it on your ATS.'}
      addButtonText={'Add Candidate'}
      onClickHref={'/applicant_tracking/candidate_wizard'}
      disabled={!props.policies.addCandidateEnabled}
    />
  ) : (
    <>
      {alert?.message && (
        <Alert
          type={alert.type}
          autoClearTimeout={4000}
          clearable={true}
          onClose={() => setAlert(null)}
        >
          {alert.message}
        </Alert>
      )}
      <ListApplicationsPageHeader
        alertMessage={props.alertMessage}
        currentUser={props.currentUser}
        candidates={candidates}
        selectedCandidates={selectedCandidates}
        addCandidateEnabled={props.policies.addCandidateEnabled}
        emailAccountIntegration={props.emailAccountIntegration}
        reloadPage={reloadPage}
        setAlert={setAlert}
        hrisEmployees={hrisEmployees}
        hrisProvider={props.hrisProvider}
      />
      <ListApplicationsPageBody
        candidates={candidates}
        currentUser={props.currentUser}
        emailAccountIntegration={props.emailAccountIntegration}
        error={error}
        filterStates={filterStates}
        filterTypes={filterTypes}
        hrisEmployees={hrisEmployees}
        hrisProvider={props.hrisProvider}
        isLoading={isLoading}
        selectedCandidates={selectedCandidates}
        totalFilteredCandidates={totalFilteredCandidates}
        onSearch={performSearch}
        setSelectedCandidates={setSelectedCandidates}
        onFilterStatesUpdate={setFilterStates}
        setFilterTypes={setFilterTypes}
        reloadPage={reloadPage}
        setAlert={setAlert}
      />
    </>
  );
}
