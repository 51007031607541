import momentTimezone from 'moment-timezone';
import { appendQueryParam } from '../../../../../utils/url';

const SCHEDULE_FROM_NEXT_WEEK_HOURS = 168;

export function appendDateQueryParamsToUrl(
  url: string,
  scheduleFromHours: number,
  dateRange: number,
  timezone: string,
): string {
  const dateQueryParams = buildDateQueryParams(
    scheduleFromHours,
    dateRange,
    timezone,
  );

  let newUrl = url;
  for (const [key, value] of Object.entries(dateQueryParams)) {
    newUrl = appendQueryParam(newUrl, key, value);
  }

  return newUrl;
}

function buildDateQueryParams(
  scheduleFromHours: number,
  dateRange: number,
  timezone: string,
): object {
  const fromDate = momentTimezone.utc();

  // we need to make sure that we show the next monday as start date when the user selects the schedule from next week
  if (scheduleFromHours == SCHEDULE_FROM_NEXT_WEEK_HOURS) {
    fromDate.add(1, 'weeks').startOf('isoWeek').utc();
  } else {
    fromDate.add(scheduleFromHours, 'hours');
  }

  const toDate = fromDate.clone().add(dateRange - 1, 'days');

  return {
    from_date: fromDate.format(),
    to_date: toDate.format(),
    timezone: timezone,
  };
}
