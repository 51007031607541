import React, { CSSProperties, ReactNode } from 'react';
import { SpanTooltip } from '../SpanTooltip';
import { Icon } from '../Icon';
import classNames from 'classnames';
import { Placement } from '@popperjs/core';

interface PropTypes {
  children: ReactNode | string;
  className?: string;
  tooltipInnerClassName?: string;
  tooltipStyle?: CSSProperties;
  colorClassName?: string;
  spanClassName?: string;
  popperClassName?: string;
  placement?: Placement;
  autoHide?: boolean;
}

export function InfoTooltip(props: PropTypes) {
  return (
    <SpanTooltip
      text={
        <Icon
          name={'bi-info-circle'}
          className={classNames(
            props.className,
            props.colorClassName ? props.colorClassName : 'text-info',
          )}
        />
      }
      tooltipInnerClassName={props.tooltipInnerClassName}
      tooltipStyle={props.tooltipStyle}
      tooltipText={props.children}
      className={props.spanClassName}
      popperClassName={props.popperClassName}
      autoHide={props.autoHide}
    />
  );
}
