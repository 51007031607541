import React from 'react';
import { CronofyOptions } from '../../../../../components/cronofy/CronofyOptions';
import { Interview } from '../../../../../entities/applicant_tracking/Interview';
import ScheduleInterviewPage from '../index';
import { User } from '../../../../../entities/User';
import { Application } from '../../../../../entities/applicant_tracking/Application';
import candidateSchedulingImage from '../../../../../images/candidate-experience-mailer-new-survey.png';
import { InterviewCandidateUser } from '../../../../../entities/applicant_tracking/InterviewCandidateUser';
import {
  InterviewDatePickerFooter,
  UpsertSelfScheduleInterviewPropTypes,
} from '../InterviewDatePicker/InterviewDatePickerFooter';
import { InterviewCandidateUserService } from '../../../../../services/applicant_tracking/InterviewCandidateUserService';
import { VcsProvider } from '../../../../../enums/IntegrationProvider/VcsProvider';
import { appendCurrentRedirectUri } from '../../../../../utils/url';
import { redirectWithAlert } from '../../../../../services/FlashService';

interface PropTypes {
  cronofyDatePickerOptions: CronofyOptions;
  interview: Interview;
  application: Application;
  availableParticipants: User[];
  meetingProviders: VcsProvider[];
  interviewCandidateUser: InterviewCandidateUser;
  timezone: string;
  meetingProvider: VcsProvider;
  meetingOrganizer: User;
}

export default function UpdateSelfScheduleInterview(props: PropTypes) {
  const participantIds = props.interview.interviewers.map(
    (ihm) => ihm.hiringMember.user.id,
  );

  const onConfirm = async (data: UpsertSelfScheduleInterviewPropTypes) => {
    try {
      await InterviewCandidateUserService.update(
        props.interviewCandidateUser.id,
        data.meetingOrganizer.id,
        data.interviewerUserIds,
        data.meetingProvider,
        data.durationMinutes,
        data.dateRange,
        data.scheduleFromHours,
        data.timezone,
      );

      redirectWithAlert(
        `/applications/${props.application.id}?tab=interviews`,
        'The interview was successfully updated!',
      );
    } catch (e) {
      window.location.href = appendCurrentRedirectUri(
        `/applicant_tracking/interview_candidate_users/schedule/error`,
      );
    }
  };

  return (
    <ScheduleInterviewPage
      applicationIds={[props.application.id]}
      interview={props.interview}
      title={'Let the candidate schedule the interview'}
      cronofyDatePickerOptions={props.cronofyDatePickerOptions}
      candidate={props.application.candidate}
      participants={props.availableParticipants.filter(
        (user) => participantIds.indexOf(user.id) >= 0,
      )}
      availableParticipants={props.availableParticipants}
      interviewStage={props.interview.interviewStage}
      meetingProviders={props.meetingProviders}
      meetingProvider={props.meetingProvider}
      durationMinutes={props.interviewCandidateUser.meetingDuration}
      scheduleFromHours={props.interviewCandidateUser.scheduleFromHours}
      dateRange={props.interviewCandidateUser.dateRange}
      timezone={props.timezone}
      customImage={candidateSchedulingImage}
      onConfirm={(_data) => null}
      candidateView={false}
      selfScheduleView={true}
      meetingOrganizer={props.meetingOrganizer}
      allowChangingMeetingOrganizer={true}
      allowIgnoreParticipantCalendar={false}
      interviewDatePickerFooter={
        <InterviewDatePickerFooter
          buttonText='Update Availability'
          description={
            <>
              Confirm the team availability, which will be sent later to the
              <br></br>
              candidate. No communication will be sent at this stage.
            </>
          }
          onConfirm={onConfirm}
        />
      }
    />
  );
}
