import React from 'react';
import { Button } from 'reactstrap';
import { SelectOption } from '../../../../../../components/Select';
import { ApplicationTag } from '../../../../../../entities/v1/applicant_tracking/ApplicationTag';
import { AlertObject } from '../../../../../../components/Alert';
import { AddApplicationTagButton } from './AddApplicationTagButton';

interface PropTypes {
  setIsModalOpen: (isOpen: boolean) => void;
  selectedTags: SelectOption[];
  onTagsAdded: (newTags: ApplicationTag[]) => void;
  setAlert: (alert: AlertObject) => void;
  applicationId: number;
}

export function AddAndCancelButtons(props: PropTypes) {
  return (
    <div className='d-flex mt-4 justify-content-end'>
      <Button
        color='danger'
        className='me-2'
        onClick={() => props.setIsModalOpen(false)}
      >
        Cancel
      </Button>
      <AddApplicationTagButton
        applicationId={props.applicationId}
        selectedTags={props.selectedTags}
        onTagsAdded={props.onTagsAdded}
        setAlert={props.setAlert}
        setIsModalOpen={props.setIsModalOpen}
      />
    </div>
  );
}
