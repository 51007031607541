import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { EmptyState } from '../../../components/EmptyState';
import { Alert, AlertObject } from '../../../components/Alert';
import { JobDetails } from '../../../entities/JobDetails';
import { User } from '../../../entities/User';
import { JobPipelineBoard } from './JobPipelineBoard';
import { HeadingRow } from '../HeadingRow';
import { SortFilter } from './SortFilter';
import { AccountIntegration } from '../../../entities/AccountIntegration';
import { SortingColumn } from '../../../utils/sorting';
import {
  mapSortingColumnToQueryParam,
  mapSortingDirectionToQueryParam,
  parseSortingColumn,
} from './SortFilter/SortingColumn';
import {
  QueryParam,
  appendQueryParamToCurrentTab,
  removeQueryParamFromCurrentTab,
} from '../../../utils/url';
import { PipelineJobStagesService } from '../../../services/v1/applicant_tracking/PipelineJobStagesService';
import { PipelineJobStage } from '../../../entities/v1/applicant_tracking/PipelineJobStage';

interface PropTypes {
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  jobDetails: JobDetails;
  applyAndScheduleLinkEnabled: boolean;
  selfSchedulingLinkOptionsEnabled: boolean;
}

export interface FilterState {
  sortingColumn: SortingColumn;
}

const AVAILABLE_QUERY_PARAMS = ['sorting_column', 'sorting_direction'];

export function mapToQueryParams(filterStates: FilterState): QueryParam[] {
  return [
    mapSortingColumnToQueryParam(filterStates.sortingColumn.columnName),
    mapSortingDirectionToQueryParam(filterStates.sortingColumn.direction),
  ].filter((qp) => qp != null);
}

export function updateQueryParams(filterState: FilterState) {
  const mappedQueryParams = mapToQueryParams(filterState);

  mapToQueryParams(filterState)
    .filter((qp) => qp.value)
    .forEach((qp) => {
      appendQueryParamToCurrentTab(qp.key, qp.value as string);
    });

  AVAILABLE_QUERY_PARAMS.forEach((qp) => {
    if (!mappedQueryParams.find((p) => p.key === qp)?.value) {
      removeQueryParamFromCurrentTab(qp);
    }
  });
}

export function parseCurrentFilterState(): FilterState {
  return {
    sortingColumn: parseSortingColumn(),
  };
}

export function PipelineTab(props: PropTypes) {
  // key used to rerender the components
  const [key, setKey] = useState<number>(1);
  const [alert, setAlert] = useState<AlertObject>(null);
  const [pipelineJobStages, setPipelineJobStages] =
    useState<PipelineJobStage[]>(null);
  const [filterState, setFilterState] = useState<FilterState>(
    parseCurrentFilterState(),
  );

  const loadJobStages = async () => {
    PipelineJobStagesService.list(props.jobDetails.id)
      .then((jobStageList) => {
        setPipelineJobStages(jobStageList.jobStages);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const reloadApplications = () => {
    // Recalculate Pass-through Rates
    loadJobStages();
    // Changing the key to make the children components reload
    setKey((k) => k + 1);
  };

  useEffect(() => {
    updateQueryParams(filterState);
    reloadApplications();
  }, [filterState]);

  if (!pipelineJobStages) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <HeadingRow date={props.jobDetails.updatedAt} tabName='Pipeline'>
        <SortFilter filterState={filterState} setFilterState={setFilterState} />
      </HeadingRow>
      {pipelineJobStages?.length > 0 ? (
        <>
          {alert?.message && (
            <Alert
              type={alert.type}
              autoClearTimeout={4000}
              clearable={true}
              onClose={() => setAlert(null)}
            >
              {alert.message}
            </Alert>
          )}
          <JobPipelineBoard
            key={key}
            currentUser={props.currentUser}
            emailAccountIntegration={props.emailAccountIntegration}
            jobDetails={props.jobDetails}
            pipelineJobStages={pipelineJobStages}
            reloadJobStages={loadJobStages}
            setAlert={setAlert}
            sortingColumn={filterState.sortingColumn}
            applyAndScheduleLinkEnabled={props.applyAndScheduleLinkEnabled}
            selfSchedulingLinkOptionsEnabled={
              props.selfSchedulingLinkOptionsEnabled
            }
          />
        </>
      ) : (
        <EmptyState
          title='No job stages were found for this job'
          text='If it is not expected, please contact our team at support@screenloop.com'
          cardless={true}
        />
      )}
    </>
  );
}
