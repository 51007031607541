import classNames from 'classnames';
import React from 'react';
import ReactSelect, { ActionMeta } from 'react-select';
import { selectStyle } from './selectStyle';
import { selectTheme } from './selectTheme';
import { Option } from './Option';
import { ClearIndicator } from './ClearIndicator';
import { DropdownIndicator } from './DropdownIndicator';
import { Size } from '../../utils/types/Size';
import { isSearchable } from '../../utils/reactSelect';

export interface SelectOption {
  value: string;
  label: string;
  selected?: boolean;
  subLabel?: string | React.ReactNode;
  disabled?: boolean;
  classNames?: string;
  isTitle?: boolean;
}

export interface CommonSelectPropTypes<OptionType> {
  /** Selected option. */
  selected: OptionType;
  /** Custom name for the select component. */
  name?: string;
  /** Custom class name for the select component. */
  className?: string;
  /** Indicates if the selected option is clearable. Defaults to true */
  isClearable?: boolean;
  /** Placeholder for the selected value */
  placeholder?: string;
  /** Called when the input changes, with the newly selected option. */
  onChange?: (newOption: OptionType, action?: ActionMeta<string>) => void;
  /** Called when the input is cleared. */
  onClear?: () => void;
  testId?: string;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  /** Changes the height of the dropdown list. */
  maxMenuHeight?: number;
  /** Size of the select component. Valid values: small or medium. Default: medium. */
  autoFocus?: boolean;
  size?: Size;
  isLoading?: boolean;
  isSearchable?: boolean;
  autoHideKeyboardOnSmallerScreen?: boolean;
}

export interface PropTypes extends CommonSelectPropTypes<SelectOption> {
  /** Options available to be selected. */
  options: SelectOption[];
}

export function Select(props: PropTypes) {
  const disabled = props.disabled ?? false;

  return (
    <ReactSelect
      autoFocus={props.autoFocus}
      name={props.name}
      className={classNames(
        props.className,
        props.size === 'sm' ? 'fs-6' : 'fs-5',
      )}
      classNamePrefix='tags'
      id={props.testId}
      options={props.options}
      value={props.selected ?? null}
      placeholder={
        <div className='text-truncate'>{props.placeholder || 'Select...'}</div>
      }
      isClearable={props.isClearable}
      isDisabled={disabled}
      onChange={(option: SelectOption, action: ActionMeta<string>) =>
        props.onChange?.(option, action)
      }
      menuPlacement={props.menuPlacement || 'auto'}
      components={{
        ClearIndicator,
        DropdownIndicator,
        Option,
      }}
      theme={selectTheme}
      styles={selectStyle(props.size || 'md')}
      maxMenuHeight={props.maxMenuHeight}
      isLoading={props.isLoading}
      isSearchable={isSearchable(
        props.isSearchable,
        props.autoHideKeyboardOnSmallerScreen,
      )}
    />
  );
}
