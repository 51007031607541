import React from 'react';
import { Chip, ChipColor } from '../../../../../../../../components/Chip';
import { channelTypeLabel } from '../../../../../../../../utils/applicant_tracking/sources';
import { NewListCandidateApplication } from '../../../../../../../../entities/v1/applicant_tracking/NewListCandidate';

interface PropTypes {
  application?: NewListCandidateApplication;
}

export function Channel(props: PropTypes) {
  if (props.application == null || props.application.source == null)
    return (
      <div className='d-flex justify-content-center'>
        <span>-</span>
      </div>
    );

  return (
    <div className='d-flex align-items-center'>
      <Chip
        className={'mw-100 text-truncate py-1 px-2h fw-semibold'}
        color={ChipColor.Gray}
      >
        {channelTypeLabel(props.application?.source?.channelType)}
      </Chip>
    </div>
  );
}
