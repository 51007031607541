import React, { useEffect, useRef, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { IconButton } from '../../../components/IconButton';

interface PropTypes {
  searchOccurrences: number;
  selectedSearchOccurenceIndex: number | null;
  onFindString: (findString: string) => void;
  onGoToPreviousSearchOccurence: () => void;
  onGoToNextSearchOccurence: () => void;
  expanded: boolean;
  onExpand: (v: boolean) => void;
}

export function TranscriptSearchBox(props: PropTypes) {
  const [focused, setFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const screenWidth = window.innerWidth;
  const smScreen = screenWidth < 1500;
  const xsmScreen = screenWidth < 1250;

  const expanded = focused;

  useEffect(() => {
    props.onExpand(expanded);
  }, [expanded]);

  function handleInput(e) {
    props.onFindString(e.target.value);
  }

  function handleKeyDown(e) {
    if (e.key !== 'Enter') return;
    props.onGoToNextSearchOccurence();
  }

  function handleContainerBlur(e: React.FocusEvent<HTMLDivElement>) {
    // Collapse only if the new focus is outside the container
    if (
      containerRef?.current &&
      !containerRef.current.contains(e.relatedTarget as Node)
    ) {
      setFocused(false);
    }
  }

  return (
    <div
      ref={containerRef}
      tabIndex={-1}
      onFocus={() => setFocused(true)}
      onBlur={handleContainerBlur}
      className={`input-group input-group-sm d-flex align-items-center ${
        !expanded ? styles.container : ''
      }`}
    >
      <DebounceInput
        minLength={3}
        debounceTimeout={300}
        type='search'
        className={classNames(
          'form-control',
          styles.input,
          xsmScreen
            ? styles['search-input-xsm']
            : `search-input ${styles['search-input-sm']}`,
        )}
        placeholder='Search (min. 3 chars)'
        onChange={handleInput}
        onFocus={() => setFocused(true)}
        onKeyDown={handleKeyDown}
      />
      {expanded && (
        <>
          <div
            className={classNames(
              'input-group-text bg-white text-secondary px-2',
              styles.occurrences,
            )}
          >
            {props.selectedSearchOccurenceIndex != null
              ? props.selectedSearchOccurenceIndex + 1
              : 0}
            /{props.searchOccurrences || 0}
          </div>

          <>
            <IconButton
              color='borderless'
              size={smScreen ? 'sm' : 'md'}
              iconName='bi-chevron-up'
              onClick={props.onGoToPreviousSearchOccurence}
              disabled={props.searchOccurrences === 0}
            />
            <IconButton
              color='borderless'
              size={smScreen ? 'sm' : 'md'}
              iconName='bi-chevron-down'
              onClick={props.onGoToNextSearchOccurence}
              disabled={props.searchOccurrences === 0}
            />
          </>
        </>
      )}
    </div>
  );
}
