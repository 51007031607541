import React from 'react';
import { IconButton } from '../IconButton';
import { LoadingSpinnerIconSpan } from '../LoadingSpinnerIconSpan';

interface PropTypes {
  isLoading: boolean;
  loadElements: (...args: any[]) => Promise<void>;
}

export function LoadMoreButton(props: PropTypes) {
  return (
    <div className='d-flex col-12 justify-content-center'>
      {props.isLoading ? (
        <LoadingSpinnerIconSpan
          iconClassName='text-info'
          textClassName={'ps-2 fs-5 fw-normal text-dark-200 '}
          text={'Loading'}
        />
      ) : (
        <IconButton
          buttonText='Load More'
          color='borderless'
          iconName='bi-plus-lg'
          onClick={async () => {
            props.loadElements();
          }}
        />
      )}
    </div>
  );
}
