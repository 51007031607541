import React from 'react';
import {
  AutoRejectTrigger,
  PushToHrisTrigger,
  SendEmailTrigger,
  SendSelfSchedulingEmailTrigger,
  Trigger,
} from '../../../../entities/applicant_tracking/Trigger';
import { Icon } from '../../../../components/Icon';
import { SpanTooltip } from '../../../../components/SpanTooltip';
import { humanize } from '../../../../utils/humanize';
import { getHrisName } from '../../../../utils/hris';
import classNames from 'classnames';
import { PipelineJobStage } from '../../../../entities/v1/applicant_tracking/PipelineJobStage';

interface PropTypes {
  className?: string;
  pipelineJobStage: PipelineJobStage;
  jobStageTriggers: Trigger[];
}

function buildAutoRejectTriggerTooltipText(
  trigger: AutoRejectTrigger,
  pipelineJobStage: PipelineJobStage,
) {
  if (pipelineJobStage.order > 1) {
    return null;
  }

  const triggerQuestionText = trigger.rejectReason;
  const triggerQuestionAnswer = trigger.rejectAnswer ? 'Yes' : 'No';

  return `Auto-rejects candidates if the answer to the application form question "${triggerQuestionText}" is "${triggerQuestionAnswer}"`;
}

function buildPushToHrisTriggerTooltipText(trigger: PushToHrisTrigger) {
  return `Exports candidates to ${getHrisName(trigger.hrisProvider)}`;
}

function buildSendEmailTriggerTooltipText(trigger: SendEmailTrigger) {
  return `Sends "${
    trigger.emailTemplateName
  }" email to candidates in "${humanizeApplicationStatuses(
    trigger.applicationStatuses,
  )}" status(es) ${buildDelayDaysInfoText(trigger.delayDays)}`;
}

function buildSendSelfSchedulingEmailTriggerTooltipText(
  trigger: SendSelfSchedulingEmailTrigger,
) {
  return `Sends self-scheduling link to candidates ${buildDelayDaysInfoText(
    trigger.delayDays,
  )}`;
}

function buildDelayDaysInfoText(delayDays: number) {
  if (delayDays == 0) {
    return 'with no delay days';
  }

  return `in ${delayDays} day(s)`;
}

function humanizeApplicationStatuses(statuses: string[]): string {
  return statuses.map((s) => humanize(s)).join(', ');
}

function buildTriggerTooltipText(
  trigger: Trigger,
  pipelineJobStage: PipelineJobStage,
) {
  const triggerType = trigger.type;

  switch (triggerType) {
    case 'auto_reject':
      return buildAutoRejectTriggerTooltipText(
        trigger as AutoRejectTrigger,
        pipelineJobStage,
      );
    case 'push_to_hris':
      return buildPushToHrisTriggerTooltipText(trigger as PushToHrisTrigger);
    case 'send_email':
      return buildSendEmailTriggerTooltipText(trigger as SendEmailTrigger);
    case 'send_self_scheduling_email':
      return buildSendSelfSchedulingEmailTriggerTooltipText(
        trigger as SendSelfSchedulingEmailTrigger,
      );
    default:
      return humanize(triggerType);
  }
}

function TriggersTooltipText(
  triggers: Trigger[],
  pipelineJobStage: PipelineJobStage,
) {
  if (triggers.length == 1) {
    return (
      <p className={'mb-0'}>
        {buildTriggerTooltipText(triggers[0], pipelineJobStage)}
      </p>
    );
  }

  const triggerTooltipTexts = triggers
    .map((t) => buildTriggerTooltipText(t, pipelineJobStage))
    .filter(Boolean);

  return triggerTooltipTexts.map((text, i) => (
    <p
      key={i}
      className={classNames(
        'fw-normal',
        i == triggerTooltipTexts.length - 1 && 'mb-0',
      )}
    >
      <b>{`Trigger ${i + 1}: `}</b>
      {text}
    </p>
  ));
}

function triggerBelongsToJobStage(
  trigger: Trigger,
  pipelineJobStage: PipelineJobStage,
): boolean {
  switch (trigger.type) {
    case 'auto_reject':
      return pipelineJobStage.order === 1;
    case 'push_to_hris':
      return pipelineJobStage.jobStage.name === 'Hired';
    default:
      return trigger.stageIds?.includes(pipelineJobStage.jobStageId);
  }
}

export function getJobStageTriggers(
  pipelineJobStage: PipelineJobStage,
  triggers?: Trigger[],
) {
  return triggers
    ?.filter((t) => triggerBelongsToJobStage(t, pipelineJobStage))
    .filter(Boolean);
}

export function TriggersIcon(props: PropTypes) {
  if (!props.jobStageTriggers || props.jobStageTriggers.length == 0) {
    return null;
  }

  return (
    <SpanTooltip
      text={
        <Icon name={'bi-lightning-charge-fill'} className={'text-blue-500'} />
      }
      tooltipText={TriggersTooltipText(
        props.jobStageTriggers,
        props.pipelineJobStage,
      )}
      className={classNames('ms-auto', props.className)}
      tooltipStyle={{ maxWidth: '484px' }}
      placement={'top'}
      tooltipInnerClassName={'px-3 py-2'}
    />
  );
}
