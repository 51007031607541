import * as DOMPurify from 'dompurify';

export function updateLinksToNewTab(htmlContent: string): string {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlContent;

  const links = tempDiv.querySelectorAll('a');
  links.forEach((link) => {
    link.setAttribute('target', '_blank');
  });

  return tempDiv.innerHTML;
}

export function domPurify(
  htmlContent: string,
  options?: DOMPurify.Config,
  excludeTargetAttributes?: boolean,
): string {
  const linkOptions = options || {};

  if (!excludeTargetAttributes) {
    linkOptions['ADD_ATTR'] = ['target'];
  }

  return DOMPurify.sanitize(htmlContent, linkOptions);
}
