import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { CenteredLayout } from '../../../../components/CenteredLayout';
import { JobStageApproval } from './JobStageApproval';
import styles from './styles.module.scss';
import { JobStageHeader } from './JobStageHeader';
import { JobStageSubHeader } from './JobStageSubHeader';
import { JobStageApplications } from './JobStageApplications';
import { MoveCandidateProps } from '../../components/MoveCandidateStageModal';
import { User } from '../../../../entities/User';
import { HiringMember } from '../../../../entities/HiringMember';
import { DragEndEvent, useDroppable } from '@dnd-kit/core';
import {
  PipelineApplication,
  PipelineApplicationList,
} from '../../../../entities/applicant_tracking/PipelineApplication';
import { JobDetails } from '../../../../entities/JobDetails';
import { AlertObject } from '../../../../components/Alert';
import { JobStageBulkReviewButton } from './JobStageBulkReviewButton';
import { AccountIntegration } from '../../../../entities/AccountIntegration';
import { PipelineApplicationService } from '../../../../services/applicant_tracking/PipelineApplicationsService';
import { SortingColumn } from '../../../../utils/sorting';
import { PipelineJobStage } from '../../../../entities/v1/applicant_tracking/PipelineJobStage';
import _ from 'lodash';
import consumer from '../../../../channels/consumer';
import { handleDragEndEvent, handleMoveToStage } from './utils/moveToStage';

interface PropTypes {
  changeStageDragEndEvent: DragEndEvent;
  addJobStageIdLoaded: (jobStageId: number) => void;
  emailAccountIntegration: AccountIntegration;
  jobDetails: JobDetails;
  pipelineJobStage: PipelineJobStage;
  currentHiringMember?: HiringMember;
  currentUser: User;
  onMoveCandidate?: (action?: MoveCandidateProps) => void;
  reloadJobStages: () => Promise<void>;
  setAlert: (alert: AlertObject) => void;
  sortingColumn: SortingColumn;
  applyAndScheduleLinkEnabled: boolean;
  selfSchedulingLinkOptionsEnabled: boolean;
}

const HIRED_STAGE = 'Hired';

function isNotHiredStage(jobStageName: string): boolean {
  return jobStageName.toLowerCase() !== HIRED_STAGE.toLowerCase();
}

export function JobStageLane(props: PropTypes) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.pipelineJobStage.jobStage.name,
  });
  const [applications, setApplications] = useState<PipelineApplication[]>([]);
  const [currentApplicationsPage, setCurrentApplicationsPage] =
    useState<PipelineApplicationList>(null);
  const [totalApplications, setTotalApplications] = useState<number>(0);

  function loadApplications(reload = false) {
    const page = reload ? 1 : currentApplicationsPage?.nextPage || 1;

    PipelineApplicationService.list({
      jobId: props.jobDetails.id,
      stagePlanTemplateId: props.pipelineJobStage.stagePlanTemplateId,
      page: page,
      pageSize: 10,
      sortColumn: props.sortingColumn.columnName,
      sortDirection: props.sortingColumn.direction,
    })
      .then((applicationList) => {
        if (page === 1) {
          setApplications(applicationList.applications);
        } else {
          setApplications(applications.concat(...applicationList.applications));
        }

        setCurrentApplicationsPage(applicationList);
        setTotalApplications(applicationList.entriesCount);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        props.addJobStageIdLoaded(props.pipelineJobStage.jobStageId);
      });
  }

  useEffect(() => {
    loadApplications();
  }, []);

  useEffect(() => {
    handleDragEndEvent(
      props.changeStageDragEndEvent,
      props.pipelineJobStage,
      applications,
      setApplications,
      setTotalApplications,
    );
  }, [props.changeStageDragEndEvent]);

  async function refetchApplication(
    userId?: number,
    stagePlanTemplateId?: number,
  ) {
    // we don't want to reload the applications everytime there is a new application or a change performed by other user
    if (props.currentUser.id !== userId) return;

    loadApplications(true);

    // in case there is a change of stage, we need to recalculate the PTR
    // guarantee that this is only performed once
    if (stagePlanTemplateId === props.pipelineJobStage.stagePlanTemplateId) {
      props.reloadJobStages();
    }
  }

  useEffect(() => {
    const dbRefetchApplication = _.debounce(refetchApplication, 500);
    const sub = consumer.subscriptions.create(
      {
        channel: 'ApplicantTracking::ApplicationsChannel',
        job_id: props.jobDetails.id,
      },
      {
        received({
          userId,
          stagePlanTemplateId,
        }: {
          userId?: number;
          stagePlanTemplateId?: number;
        }) {
          dbRefetchApplication(userId, stagePlanTemplateId);
        },
      },
    );

    return () => consumer.subscriptions.remove(sub);
  }, []);

  const onMoveCandidate = (
    application: PipelineApplication,
    action?: MoveCandidateProps,
  ) => {
    props.onMoveCandidate(action);

    handleMoveToStage(
      application,
      props.pipelineJobStage,
      setApplications,
      setTotalApplications,
      action,
    );
  };

  const onRejectApplication = (applicationId: number) => {
    setApplications((applications) => {
      return applications.filter((a) => a.id !== applicationId);
    });
    setTotalApplications((total) => total - 1);
  };

  return (
    <CenteredLayout
      heightClass={classNames('h-100', styles['lane'])}
      innerContainerClassName={classNames(
        'd-flex flex-column h-100 bg-white rounded-3 pb-4',
        isOver && styles['hover-border'],
      )}
      innerref={setNodeRef}
    >
      <JobStageHeader
        pipelineJobStage={props.pipelineJobStage}
        jobDetails={props.jobDetails}
        totalApplications={totalApplications}
        applyAndScheduleLinkEnabled={props.applyAndScheduleLinkEnabled}
        selfSchedulingLinkOptionsEnabled={
          props.selfSchedulingLinkOptionsEnabled
        }
        sortingColumn={props.sortingColumn}
      />
      <JobStageSubHeader candidatesNumber={totalApplications} />
      {props.pipelineJobStage.bulkReviewEnabled &&
        applications.length > 0 &&
        isNotHiredStage(props.pipelineJobStage.jobStage.name) && (
          <JobStageBulkReviewButton
            jobId={props.jobDetails.id}
            jobStageId={props.pipelineJobStage.jobStageId}
            sortingColumn={props.sortingColumn}
          />
        )}
      {isNotHiredStage(props.pipelineJobStage.jobStage.name) && (
        <JobStageApproval
          passthroughRate={props.pipelineJobStage.passthroughRate}
        />
      )}
      <JobStageApplications
        applications={applications}
        currentHiringMember={props.currentHiringMember}
        currentUser={props.currentUser}
        emailAccountIntegration={props.emailAccountIntegration}
        hasMoreApplications={currentApplicationsPage?.nextPage != null}
        jobStatus={props.jobDetails.status}
        loadApplications={loadApplications}
        offerEnabled={props.pipelineJobStage.offerEnabled}
        onMoveCandidate={onMoveCandidate}
        onRejectApplication={onRejectApplication}
        pipelineJobStage={props.pipelineJobStage}
        setAlert={props.setAlert}
        totalApplications={totalApplications}
      />
    </CenteredLayout>
  );
}
