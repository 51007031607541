import React from 'react';
import { Header } from './Header';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { ShortApplication } from './ShortApplication';
import { ApplicationDetailForm } from './ApplicationDetailForm';
import { Step } from './ShowPage';
import { ApplyAndScheduleLinkSetting } from '../../../entities/v1/applicant_tracking/ApplyAndScheduleLinkSetting';

interface PropTypes {
  register: UseFormRegister<ShortApplication>;
  getValues: UseFormGetValues<ShortApplication>;
  setValue: UseFormSetValue<ShortApplication>;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  isValid: boolean;
  setStep: (n: Step) => void;
  settings: ApplyAndScheduleLinkSetting;
}

export function ApplicationDetailStep(props: PropTypes) {
  return (
    <div>
      <Header
        title="You're almost there!"
        subtitle='Would you like to add more information about yourself?'
      />
      <ApplicationDetailForm
        register={props.register}
        getValues={props.getValues}
        setValue={props.setValue}
        onSubmit={props.onSubmit}
        isValid={props.isValid}
        setStep={props.setStep}
        settings={props.settings}
      />
    </div>
  );
}
