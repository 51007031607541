import React, { Fragment, useState } from 'react';
import {
  ApplicationListingFilters,
  DEFAULT_FILTER_TYPES,
  FilterType,
} from '../utils';
import { JobStagesFilter } from './JobStagesFilter';
import { Button, Row } from 'reactstrap';
import { JobsFilter } from './JobsFilter';
import { StatusFilter } from './StatusFilter';
import { DateRangeFilter } from './DateRangeFilter';
import { IconButton } from '../../../../../../../components/IconButton';
import { SourcesFilter } from './SourcesFilter';
import { RightToWorkLocationFilter } from './RightToWorkLocationFilter';
import { ChannelsFilter } from './ChannelsFilter';
import { NameFilter } from './NameFilter';
import { EmailFilter } from './EmailFilter';
import { LocationFilter } from './LocationFilter';
import { SaveSegmentModal } from './SaveSegmentModal';
import { AlertObject } from '../../../../../../../components/Alert';
import { SearchSegment } from '../../../../../../../entities/v1/applicant_tracking/SearchSegment';
import { ResumeFilter } from './ResumeFilter';

interface PropTypes {
  filters: ApplicationListingFilters;
  filterTypes: FilterType[];
  isLoading: boolean;
  onCreateSegment: (segment: SearchSegment) => void;
  onSearch: () => void;
  setAlert: (alert: AlertObject) => void;
  setFilters: (filters: ApplicationListingFilters) => void;
  selectDefaultSegment: () => void;
}

const FILTER_COMPONENTS = {
  jobs: JobsFilter,
  jobStages: JobStagesFilter,
  status: StatusFilter,
  dateRange: DateRangeFilter,
  channels: ChannelsFilter,
  sources: SourcesFilter,
  rightToWorkLocations: RightToWorkLocationFilter,
  email: EmailFilter,
  resume: ResumeFilter,
  name: NameFilter,
  location: LocationFilter,
};

export function FiltersBody(props: PropTypes) {
  const [isSaveSegmentModalOpen, setIsSaveSegmentModalOpen] = useState(false);
  const defaultFilters: JSX.Element[] = DEFAULT_FILTER_TYPES.map((filter) => {
    const FilterComponent = FILTER_COMPONENTS[filter];
    return (
      <FilterComponent
        key={filter}
        isLoading={props.isLoading}
        filters={props.filters}
        setFilters={props.setFilters}
      />
    );
  });
  const additionalFilters: JSX.Element[] = props.filterTypes
    .filter((filter) => FILTER_COMPONENTS[filter.value])
    .map((filter) => {
      const FilterComponent = FILTER_COMPONENTS[filter.value];
      return (
        <FilterComponent
          key={filter}
          isLoading={props.isLoading}
          filters={props.filters}
          setFilters={props.setFilters}
        />
      );
    });

  return (
    <div className='mt-3'>
      <Row className='gx-3'>
        {defaultFilters.map((filter, index) => (
          <Fragment key={index}>{filter}</Fragment>
        ))}
      </Row>
      <Row className='gx-3'>
        {additionalFilters.filter(Boolean).map((filter, index) => (
          <Fragment key={index}>{filter}</Fragment>
        ))}
      </Row>
      <div className='d-flex justify-content-end mt-6h'>
        <IconButton
          buttonText='Save as Segment'
          iconName='bi-floppy'
          color='borderless'
          buttonClass='me-3'
          onClick={() => setIsSaveSegmentModalOpen(true)}
        />
        <Button color='danger' onClick={props.selectDefaultSegment}>
          Reset
        </Button>
        <IconButton
          iconName='bi-search'
          buttonText='Search'
          color='primary'
          buttonClass='ms-3'
          onClick={props.onSearch}
        />
      </div>
      <SaveSegmentModal
        isOpen={isSaveSegmentModalOpen}
        filters={props.filters}
        setAlert={props.setAlert}
        onCreateSegment={props.onCreateSegment}
        onClose={() => setIsSaveSegmentModalOpen(false)}
      />
    </div>
  );
}
