import React from 'react';
import { EmptyState } from '../../../../../../components/EmptyState';
import { IconButton } from '../../../../../../components/IconButton';
import { PDFViewer } from '../../../../../../components/PDFViewer';
import { isPDFUrl } from '../../../../../../utils/pdfHandler';
import classNames from 'classnames';
import styles from '../../styles.module.scss';

interface PropTypes {
  url: string;
  loadingElement?: JSX.Element;
}

function EmptyResume() {
  return (
    <div className='d-flex align-items-center h-100 w-100 justify-content-center'>
      <EmptyState
        title='Resume not available'
        text='There is no resume available for this candidate.'
        classNames='w-100'
      />
    </div>
  );
}

function DocumentTypeNotSupported(props: PropTypes) {
  return (
    <div className='d-flex align-items-center h-100 w-100 justify-content-center'>
      <EmptyState
        title='Resume preview not available '
        text={
          <>
            <div className='mb-4'>
              It&apos;s not possible to preview this resume due to its format.
              Please consider downloading it instead.
            </div>
            <IconButton
              iconName='bi-arrow-down'
              buttonText='Download Resume'
              color='info'
              onClick={() => window.open(props.url, '_blank')}
            />
          </>
        }
        classNames='w-100'
      />
    </div>
  );
}

export function Resume(props: PropTypes) {
  if (!props.url) {
    return <EmptyResume />;
  }

  if (!isPDFUrl(props.url)) {
    return <DocumentTypeNotSupported {...props} />;
  }

  return (
    <div
      className={classNames(
        'h-100 my-2h mx-4',
        styles['resume-panel'],
        styles['hidden-scrollbar'],
      )}
    >
      <PDFViewer
        loadingElement={props.loadingElement}
        url={props.url}
        externalLinkTarget={'_blank'}
      />
    </div>
  );
}
