import React, { useEffect, useState } from 'react';
import { ApplicationFormTemplate } from '../../../../../entities/applicant_tracking/ApplicationFormTemplate';
import { SurveySection } from '../../../../../entities/survey_engine/SurveySection';
import { ApplicationFormSection } from './ApplicationFormSection';
import { ApplicationFormApplyButton } from './ApplicationFormApplyButton';
import { ApplicationFormCandidateConsent } from './ApplicationFormCandidateConsent';
import { useForm } from 'react-hook-form';
import { Source } from '../../../../../entities/applicant_tracking/Source';
import { PreviewJobPost } from '../../../../../entities/applicant_tracking/PreviewJobPost';
import { SubmitApplicationDto } from '../../../../../services/applicant_tracking/CareersService/SubmitApplicationDto';
import { SingleAttachmentInputFormField } from '../../../../../components/SingleAttachmentInputFormField';
import { ResumeParserInputBody } from '../../../../../components/SingleAttachmentInputFormField/ResumeParserInputBody';
import { CareersService } from '../../../../../services/applicant_tracking/CareersService';
import { ParsedResumesService } from '../../../../../services/v1/applicant_tracking/ParsedResumesService';
import { AlertObject, AlertType } from '../../../../../components/Alert';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { parseJobBoardParams } from '../../../../../utils/applicant_tracking/unified_job_boards/vonq/parse_careers_query_params';
import { CompanyInfo } from '../../../../../entities/CompanyInfo';

const SUCCESS_URL = location.pathname + '/submit/success';
const ERROR_URL = location.pathname + '/submit/error';

const MAX_WIDTH = '600px';

interface PropTypes {
  applicationFormTemplateConsent?: boolean;
  companyInfo?: CompanyInfo;
  previewMode: boolean;
  previewJobPost: PreviewJobPost;
  setAlertObject: (ao: AlertObject) => void;
}

export function ApplicationFormTab(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(true);
  const [isParsingResume, setIsParsingResume] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [applicationFormTemplate, setApplicationFormTemplate] =
    useState<ApplicationFormTemplate>(null);
  const [candidateConsentHtml, setCandidateConsentHtml] =
    useState<string>(null);
  const [enableApplicationFormAutofill, setEnableApplicationFormAutofill] =
    useState<boolean>(false);
  const [sources, setSources] = useState<Source[]>([]);

  const consentEnabled: boolean =
    props.applicationFormTemplateConsent ??
    applicationFormTemplate?.candidateConsentEnabled;

  const sourceParams = parseJobBoardParams();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    resetField,
    watch,
    formState,
    trigger,
  } = useForm<any>({
    mode: 'onTouched',
  });

  useEffect(() => {
    setIsLoading(true);

    CareersService.getApplicationForm(
      props.companyInfo.subdomain,
      props.previewJobPost.jobPostId,
    )
      .then((applicationForm) => {
        setApplicationFormTemplate(applicationForm.applicationFormTemplate);
        setCandidateConsentHtml(applicationForm.candidateConsentHtml);
        setEnableApplicationFormAutofill(
          applicationForm.enableApplicationFormAutofill,
        );
      })
      .finally(() => setIsLoading(false));
  }, [props.previewJobPost.jobPostId]);

  useEffect(() => {
    setIsLoading(true);

    CareersService.getSources(props.companyInfo.subdomain)
      .then(setSources)
      .finally(() => setIsLoading(false));
  }, [props.previewJobPost.jobPostId]);

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      setIsSubmitting(true);

      await CareersService.submitApplication(
        props.companyInfo?.subdomain,
        props.previewJobPost.jobPostId,
        {
          name: data.atsApplicationFormName,
          email: data.atsApplicationFormEmail,
          phoneNumber: data.phoneNumber,
          linkedinUrl: data.atsApplicationFormLinkedinUrl,
          resumeContent: data.atsApplicationFormResumeContent,
          resumeFilename: data.atsApplicationFormResumeFilename,
          resumeId: data.resumeId,
          coverLetterContent: data.atsApplicationFormCoverLetterContent,
          coverLetterFilename: data.atsApplicationFormCoverLetterFilename,
          portfolioUrl: data.atsApplicationFormPortfolioUrl,
          salaryExpectation: data.salaryExpectation,
          salaryCurrency: data.salaryCurrency,
          candidateConsented: data.candidateConsented,
          sourceId: data.sourceId,
          slSource: sourceParams?.source,
          externalJobBoardCampaignId: sourceParams?.campaign,
          externalJobBoardProvider: sourceParams?.provider,
          hasWorkingVisa: data.hasWorkingVisa,
          address: data.location?.address,
          city: data.location?.city,
          state: data.location?.state,
          country: data.location?.country,
          academicDegree: data.academicDegree,
          yearsExperience: data.yearsExperience,
          company: data.atsApplicationFormCompany,
          jobTitle: data.atsApplicationFormJobTitle,
          customQuestionAnswers: data.customQuestionAnswers,
        },
      );

      window.location.href = SUCCESS_URL;
    } catch (e) {
      console.error(e);
      if (e?.status === 409) {
        return (window.location.href = SUCCESS_URL);
      }

      window.location.href = ERROR_URL;
    } finally {
      setIsSubmitting(false);
    }
  });

  const watchParserResumeContent = watch('parserResumeContent');
  const watchResumeContent = watch('resumeContent');

  useEffect(() => {
    if (!watchParserResumeContent) return;

    setIsParsingResume(true);

    setValue(
      'atsApplicationFormResumeContent',
      getValues('parserResumeContent'),
    );
    setValue(
      'atsApplicationFormResumeFilename',
      getValues('parserResumeFilename'),
    );

    ParsedResumesService.create({
      base64File: watchParserResumeContent,
      subdomain: props.companyInfo?.subdomain,
    })
      .then((parsedResume) => {
        setValue(
          'atsApplicationFormName',
          `${parsedResume.firstName} ${parsedResume.lastName}`,
        );
        setValue('phoneNumber', parsedResume.phone);
        setValue('atsApplicationFormEmail', parsedResume.email);
        setValue('atsApplicationFormLinkedinUrl', parsedResume.linkedinUrl);

        setValue('academicDegree', parsedResume.educationLevel);
        setValue('atsApplicationFormCompany', parsedResume.currentCompany);
        setValue('atsApplicationFormJobTitle', parsedResume.jobTitle);
        setValue('yearsExperience', parsedResume.yearsExperience);
        setValue('atsApplicationFormPortfolioUrl', parsedResume.portfolioUrl);

        setValue('location', {
          country: parsedResume.locationCountryCode,
          state: parsedResume.locationStateCode,
          city: parsedResume.locationCity,
          address: null,
          name: parsedResume.locationName,
        });

        setValue('resumeId', parsedResume.applicationResumeId, {
          shouldValidate: true,
        });

        props.setAlertObject({
          type: AlertType.Success,
          message: (
            <span>
              <b>Autofill completed!</b> Some fields have been filled in.
            </span>
          ),
        });
      })
      .catch((_e: any) =>
        props.setAlertObject({
          type: AlertType.Danger,
          message: (
            <span>
              <b>We were unable to parse the resume!</b> Please try again.
            </span>
          ),
        }),
      )
      .finally(() => setIsParsingResume(false));
  }, [watchParserResumeContent]);

  useEffect(() => {
    setValue('resumeId', null);
  }, [watchResumeContent]);

  if (isLoading || !applicationFormTemplate) {
    return <LoadingSpinner showBackdrop />;
  }

  return (
    <>
      {(isParsingResume || isSubmitting) && <LoadingSpinner showBackdrop />}
      <form
        onSubmit={onSubmit}
        className='text-start mx-auto px-3'
        style={{ maxWidth: MAX_WIDTH }}
      >
        <div className='mb-5'>
          {enableApplicationFormAutofill && (
            <div className='pb-4'>
              <SingleAttachmentInputFormField<SubmitApplicationDto>
                getValues={getValues}
                setValue={setValue}
                formRegister={register}
                headerClassNames='fw-semibold'
                controllerContentName='parserResumeContent'
                controllerFileName='parserResumeFilename'
                fieldName={'Resume / Curriculum Vitae'}
                hideHeader={true}
                allowedFormats={['.pdf', '.doc', '.docx']}
                maxFileSizeMb={10}
                isRequired={false}
                disabled={props.previewMode}
                Body={ResumeParserInputBody}
              />
            </div>
          )}

          {applicationFormTemplate.template.sections.map(
            (section: SurveySection) => (
              <ApplicationFormSection
                key={section.id}
                section={section}
                previewMode={props.previewMode}
                previewJobPost={props.previewJobPost}
                formState={formState}
                sources={sources}
                formRegister={register}
                getValues={getValues}
                setValue={setValue}
                control={control}
                formWatch={watch}
                formResetField={resetField}
                disableSource={sourceParams != null}
                errors={formState.errors}
              />
            ),
          )}

          <ApplicationFormCandidateConsent
            candidateConsentEnabled={consentEnabled}
            previewMode={props.previewMode}
            candidateConsentHtml={candidateConsentHtml}
            formRegister={register}
            reValidate={trigger}
          />

          <ApplicationFormApplyButton
            previewMode={props.previewMode}
            disabled={!formState.isValid}
          />
        </div>
      </form>
    </>
  );
}
