import snakecaseKeys from 'snakecase-keys';
import { InterviewCandidateUser } from '../../../entities/applicant_tracking/InterviewCandidateUser';
import { ApiService } from '../../ApiService';
import { Interview } from '../../../entities/applicant_tracking/Interview';
import {
  BulkSelfSchedulingDto,
  BulkSelfSchedulingType,
} from '../../dtos/BulkSelfSchedulingDto';
import { SendEmailDto } from '../../dtos/EmailDto';

export class InterviewCandidateUserService {
  public static createSelfSchedule(
    applicationId: number,
    interviewStageId: number,
    interviewerUserIds: number[],
    meetingProvider: string,
    durationMinutes: number,
    dateRange: number,
    scheduleFromHours: number,
    timezoneId: string,
    meetingOrganizerId: number,
  ): Promise<InterviewCandidateUser> {
    return ApiService.post<InterviewCandidateUser>(
      `/api/applicant_tracking/interview_candidate_users`,
      snakecaseKeys({
        applicationId: applicationId,
        interviewStageId: interviewStageId,
        interviewerUserIds: interviewerUserIds,
        meetingProvider: meetingProvider,
        durationMinutes: durationMinutes,
        dateRange: dateRange,
        scheduleFromHours: scheduleFromHours,
        timezoneId: timezoneId,
        meetingOrganizerId: meetingOrganizerId,
      }),
    );
  }

  public static bulkCreateSelfSchedule(
    type: BulkSelfSchedulingType,
    jobId: number,
    interviewStageId: number,
    interviewerUserIds: number[],
    meetingProvider: string,
    durationMinutes: number,
    dateRange: number,
    scheduleFromHours: number,
    timezoneId: string,
    meetingOrganizerId: number,
    applicationIds?: number[],
  ): Promise<BulkSelfSchedulingDto> {
    return ApiService.post<BulkSelfSchedulingDto>(
      `/api/applicant_tracking/interview_candidate_users/bulk_create`,
      snakecaseKeys({
        type: type,
        applicationIds: applicationIds,
        jobId: jobId,
        interviewStageId: interviewStageId,
        interviewerUserIds: interviewerUserIds,
        meetingProvider: meetingProvider,
        durationMinutes: durationMinutes,
        dateRange: dateRange,
        scheduleFromHours: scheduleFromHours,
        timezoneId: timezoneId,
        meetingOrganizerId: meetingOrganizerId,
      }),
    );
  }

  public static selfSchedule(
    interviewCandidateUserSecureId: string,
    startTime: string,
    endTime: string,
    timezoneId: string,
  ): Promise<Interview> {
    return ApiService.post<Interview>(
      `/api/applicant_tracking/interview_candidate_users/${interviewCandidateUserSecureId}/schedule`,
      snakecaseKeys({
        startTime: startTime,
        endTime: endTime,
        timezoneId: timezoneId,
      }),
    );
  }

  public static sendSelfScheduleEmails(
    interviewCandidateUserIds: number[],
    props?: SendEmailDto,
  ): Promise<void> {
    if (interviewCandidateUserIds.length === 1) {
      return this.sendSelfScheduleEmail(interviewCandidateUserIds[0], props);
    }

    return this.bulkSendSelfScheduleEmail(interviewCandidateUserIds, props);
  }

  private static sendSelfScheduleEmail(
    interviewCandidateUserId: number,
    props?: SendEmailDto,
  ): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/interview_candidate_users/${interviewCandidateUserId}/send_email`,
      snakecaseKeys({ ...props }),
    );
  }

  public static bulkSendSelfScheduleEmail(
    interviewCandidateUserIds: number[],
    props?: SendEmailDto,
  ): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/interview_candidate_users/bulk_send_emails`,
      snakecaseKeys({ interviewCandidateUserIds, ...props }),
    );
  }

  public static update(
    interviewCandidateUserId: number,
    meetingOrganizerUserId: number,
    interviewerUserIds: number[],
    meetingProvider: string,
    durationMinutes: number,
    dateRange: number,
    scheduleFromHours: number,
    timezoneId: string,
  ): Promise<void> {
    return ApiService.put<void>(
      `/api/applicant_tracking/interview_candidate_users/${interviewCandidateUserId}`,
      snakecaseKeys({
        meetingOrganizerId: meetingOrganizerUserId,
        interviewerUserIds: interviewerUserIds,
        meetingProvider: meetingProvider,
        durationMinutes: durationMinutes,
        dateRange: dateRange,
        scheduleFromHours: scheduleFromHours,
        timezoneId: timezoneId,
      }),
    );
  }
}
