import snakecaseKeys from 'snakecase-keys';
import { appendRedirectUri } from '../url';
import { SortingColumn } from '../sorting';

export function bulkApplicationReviewUrl(applicationIds?: number[]) {
  const baseUrl = '/applicant_tracking/bulk_application_review';

  const encodedParams = btoa(JSON.stringify(snakecaseKeys({ applicationIds })));

  return appendRedirectUri(baseUrl) + `#${encodedParams}`;
}

export function bulkJobStageApplicationReviewUrl(
  jobId: number,
  jobStageId: number,
  sortingColumn: SortingColumn,
) {
  const baseUrl = '/applicant_tracking/bulk_application_review';

  const encodedParams = btoa(
    JSON.stringify(snakecaseKeys({ jobStageId, jobId, sortingColumn })),
  );

  return appendRedirectUri(baseUrl) + `#${encodedParams}`;
}

export function getBulkReviewData(): object {
  const urlHash = window.location.hash;

  if (!urlHash) {
    return null;
  }

  return JSON.parse(atob(urlHash.slice(1)));
}

export function getApplicationIdsToBulkReview(): number[] {
  const json = getBulkReviewData();

  if (json == null) return [];

  return json['application_ids'] || [];
}
