import React, { useEffect, useState } from 'react';
import { Card } from '../../../../components/Card';
import { IconButton } from '../../../../components/IconButton';
import { PDFViewer } from '../../../../components/PDFViewer';
import { Application } from '../../../../entities/Application';
import { RedactedResumeService } from '../../../../services/v1/applicant_tracking/RedactedResumeService';
import { RedactingLoadingState } from '../../../applicant_tracking/RedactingLoadingState';
import { Size } from '../../../../utils/types/Size';
import { useScreenSize } from '../../../../utils/useScreenSize';
import { isPDFUrl } from '../../../../utils/pdfHandler';
import {
  CVLoading,
  CVNotAvailable,
  DocumentTypeNotSupported,
} from './EmptyStates';
import { Timedelta } from '../../../../utils/timedelta';
import { ApplicationResume } from '../../../../entities/ApplicationResume';

interface PropTypes {
  application: Application;
}

const HEIGHTS: Record<Size, string> = {
  xxxl: '1020px',
  xxl: '920px',
  xl: '800px',
  lg: '512px',
  md: '320px',
  sm: '200px',
  xs: '150px',
};

// After 5 minutes, we consider the CV as not recently uploaded and show the preview not available state
const MINS_TO_CONSIDER_RECENT_CV = 5;

function recentlyUploadedCV(applicationResume: ApplicationResume) {
  return (
    Timedelta.timeTillDate(applicationResume.createdAt).toMins() >
    -MINS_TO_CONSIDER_RECENT_CV
  );
}

export function ResumePanel(props: PropTypes) {
  const [isLoadingResume, setIsLoadingResume] = useState(true);
  const [applicationResumeUrl, setApplicationResumeUrl] =
    useState<string>(null);
  const screenSize = useScreenSize();

  useEffect(() => {
    (async () => {
      if (props.application.applicationResume != null) {
        if (
          props.application.applicationResume.url == null &&
          props.application.redacted
        ) {
          const resume = await RedactedResumeService.show(
            props.application.applicationResume?.id,
          );

          setApplicationResumeUrl(resume.url);
        } else {
          setApplicationResumeUrl(props.application.applicationResume.url);
        }
      }

      setIsLoadingResume(false);
    })();
  }, [props.application.applicationResume, props.application.redacted]);

  if (props.application.applicationResume == null) {
    return <CVNotAvailable />;
  }

  if (applicationResumeUrl == null && isLoadingResume) {
    return <RedactingLoadingState redacted={props.application.redacted} />;
  }

  if (!isPDFUrl(applicationResumeUrl)) {
    if (recentlyUploadedCV(props.application.applicationResume)) {
      return <CVLoading />;
    }

    return <DocumentTypeNotSupported url={applicationResumeUrl} />;
  }

  return (
    <Card>
      <div className='text-end'>
        <IconButton
          iconName='bi-box-arrow-up-right'
          buttonText='View CV'
          buttonClass='mb-1'
          color='borderless'
          onClick={() => window.open(applicationResumeUrl, '_blank')}
        />
      </div>
      <div style={{ height: HEIGHTS[screenSize], overflowY: 'scroll' }}>
        <PDFViewer
          loadingElement={
            <RedactingLoadingState redacted={props.application.redacted} />
          }
          url={applicationResumeUrl}
          externalLinkTarget={'_blank'}
        />
      </div>
    </Card>
  );
}
