import React from 'react';
import { Button } from 'reactstrap';
import { SelectOption } from '../../../../../../../components/Select';
import { ApplicationTag } from '../../../../../../../entities/v1/applicant_tracking/ApplicationTag';
import { ApplicationTagService } from '../../../../../../../services/v1/applicant_tracking/ApplicationTagService';
import { AlertObject, AlertType } from '../../../../../../../components/Alert';

interface PropTypes {
  applicationId: number;
  selectedTags: SelectOption[];
  onTagsAdded: (newTags: ApplicationTag[]) => void;
  setAlert: (alert: AlertObject) => void;
  setIsModalOpen: (isOpen: boolean) => void;
}

export function AddApplicationTagButton(props: PropTypes) {
  const onAddApplicationTags = async () => {
    const names = props.selectedTags.map((tag) => tag.value);
    try {
      const response = await ApplicationTagService.create({
        applicationId: props.applicationId,
        names: names,
        expand: ['tag'],
      });
      props.onTagsAdded(response.applicationTags);
      props.setAlert({
        type: AlertType.Success,
        message: (
          <>
            <span className='fw-semibold'>Tags successfully added.</span>
          </>
        ),
      });
      props.setIsModalOpen(false);
    } catch (error) {
      props.setAlert({
        type: AlertType.Danger,
        message: 'There was an error adding the tags.',
      });
    }
  };
  return (
    <Button
      disabled={props.selectedTags == null || props.selectedTags.length === 0}
      color='primary'
      onClick={() => onAddApplicationTags()}
    >
      Add
    </Button>
  );
}
