import React from 'react';
import { Container } from 'reactstrap';
import { Outlet } from 'react-router-dom';
import { DeprecatedPanel } from '../../../../../components/DeprecatedPanel';
import {
  HorizontalStepper,
  ItemPropTypes,
} from '../../../../../components/HorizontalStepper';
import { BackArrow } from '../../../../../components/BackArrow';

interface PropTypes {
  entity: string;
  stepperItems: ItemPropTypes[];
  setupStep: number;
  showBackButton?: boolean;
  children?: React.ReactNode;
}

export function PageLayout(props: PropTypes) {
  return (
    <Container>
      <div className='d-flex'>
        {props.showBackButton && <BackArrow />}
        <h1 className='mb-4'>Add {props.entity}</h1>
      </div>
      <HorizontalStepper
        items={props.stepperItems}
        activeIndex={props.setupStep}
      />
      <DeprecatedPanel className={'px-4 pt-4 pb-5 mb-5'}>
        {props.children ? props.children : <Outlet />}
      </DeprecatedPanel>
    </Container>
  );
}
