export interface CurrencyOption {
  label: string;
  value: string;
  symbol: string;
}

import currencies from './currency.json';

function currencyLabel(currency: any): string {
  if (!currency[1].symbol) {
    return currency[1].name;
  }

  return `${currency[1].name} (${currency[1].symbol})`;
}

export const AVAILABLE_CURRENCIES: CurrencyOption[] = Object.entries(currencies)
  .map((currency) => {
    return {
      label: currencyLabel(currency),
      value: currency[0].toLowerCase(),
      symbol: currency[1].symbol,
    };
  })
  .sort(sortCurrency);

export const DEFAULT_CURRENCY: CurrencyOption = AVAILABLE_CURRENCIES.find(
  (c) => c.value === 'gbp',
);

export function getCurrencySymbol(currency?: string): string {
  const selectedCurrency = AVAILABLE_CURRENCIES.find(
    (c) => c.value === currency,
  );

  return selectedCurrency?.symbol || selectedCurrency?.value.charAt(0) || '';
}

function sortCurrency(a: CurrencyOption, b: CurrencyOption) {
  const mostUsedCurrencies = ['gbp', 'eur', 'usd', 'jpy'];

  if (
    mostUsedCurrencies.includes(a.value) &&
    !mostUsedCurrencies.includes(b.value)
  ) {
    return -1;
  } else if (
    mostUsedCurrencies.includes(b.value) &&
    !mostUsedCurrencies.includes(a.value)
  ) {
    return 1;
  }

  return a.label.localeCompare(b.label);
}
