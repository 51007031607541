import classNames from 'classnames';
import React from 'react';
import { PipelineJobStage } from '../../../../../entities/v1/applicant_tracking/PipelineJobStage';
import { Dropdown } from '../../../../../components/Dropdown';
import { PropTypes as DropdownActionPropTypes } from '../../../../../components/Dropdown/DropdownMenu/DropdownAction';
import { IconSpan } from '../../../../../components/IconSpan';
import { bulkJobStageApplicationReviewUrl } from '../../../../../utils/applicant_tracking/bulkApplicationReview';
import { redirectToBulkSelfSchedulingPage } from '../../../../../utils/applicant_tracking/buildSelfScheduling';
import { BulkSelfSchedulingType } from '../../../../../services/dtos/BulkSelfSchedulingDto';
import { SortingColumn } from '../../../../../utils/sorting';

interface PropTypes {
  className?: string;
  jobId: number;
  pipelineJobStage: PipelineJobStage;
  menuActions: DropdownActionPropTypes[];
}

function renderBulkSelfSchedulingAction(
  jobId: number,
  totalApplications: number,
  interviewStageId?: number,
): DropdownActionPropTypes {
  if (!interviewStageId) {
    return null;
  }

  return {
    action: () => {
      redirectToBulkSelfSchedulingPage(
        BulkSelfSchedulingType.JobStage,
        jobId,
        interviewStageId,
        totalApplications,
      );
    },
    buttonChild: (
      <IconSpan
        spanText={'Send Self-scheduling Link'}
        icon={{ name: 'bi-calendar2-check', className: 'fs-5' }}
        className={'text-info'}
      />
    ),
  };
}

function renderReviewCandidates(
  jobId: number,
  pipelineJobStage: PipelineJobStage,
  sortingColumn: SortingColumn,
): DropdownActionPropTypes {
  if (!pipelineJobStage.bulkReviewEnabled) {
    return null;
  }

  return {
    action: () =>
      (window.location.href = bulkJobStageApplicationReviewUrl(
        jobId,
        pipelineJobStage.jobStageId,
        sortingColumn,
      )),
    buttonChild: (
      <IconSpan
        spanText={'Review Candidates'}
        icon={{ name: 'bi-people', className: 'fs-5' }}
        className={'text-info'}
      />
    ),
  };
}

function renderCopySelfSchedulingLink(
  pipelineJobStage: PipelineJobStage,
  onOpen: (v: boolean) => void,
  applyAndScheduleLinkEnabled: boolean,
): DropdownActionPropTypes {
  if (!applyAndScheduleLinkEnabled || !pipelineJobStage.interviewStageId)
    return null;

  return {
    action: () => {
      onOpen(true);
    },
    buttonChild: (
      <IconSpan
        spanText={'Copy Self-Scheduling Link'}
        icon={{ name: 'bi-share', className: 'fs-5' }}
        className={'text-info'}
      />
    ),
  };
}

export function buildDropDownActions(
  jobId: number,
  pipelineJobStage: PipelineJobStage,
  totalApplications: number,
  onOpen: (v: boolean) => void,
  applyAndScheduleLinkEnabled: boolean,
  selfSchedulingLinkOptionsEnabled: boolean,
  sortingColumn: SortingColumn,
): DropdownActionPropTypes[] {
  const hasApplications = totalApplications > 0;

  return [
    selfSchedulingLinkOptionsEnabled &&
      hasApplications &&
      renderBulkSelfSchedulingAction(
        jobId,
        totalApplications,
        pipelineJobStage.interviewStageId,
      ),
    hasApplications &&
      renderReviewCandidates(jobId, pipelineJobStage, sortingColumn),
    selfSchedulingLinkOptionsEnabled &&
      renderCopySelfSchedulingLink(
        pipelineJobStage,
        onOpen,
        applyAndScheduleLinkEnabled,
      ),
  ].filter(Boolean);
}

export function JobStageDropdown(props: PropTypes) {
  if (props.menuActions.length == 0) {
    return null;
  }

  return (
    <Dropdown
      buttonClassName={classNames('btn btn-borderless bg-white')}
      buttonIcon={{
        name: 'bi-three-dots-vertical',
        className: 'text-info',
        topStyle: '-2px',
      }}
      containerClassName={classNames('me-1', props.className)}
      menuPlacementClass={'dropdown-menu-end'}
      menuActions={props.menuActions}
    />
  );
}
