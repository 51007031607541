import React from 'react';
import { User } from '../../../../entities/User';
import CandidateWizard from '../../Candidates/CandidateWizard';

interface PropTypes {
  currentUser: User;
}

export default function AddReferral(props: PropTypes) {
  return <CandidateWizard referrerUserId={props.currentUser.id} />;
}
