import React from 'react';
import { Application } from '../../../../../entities/v1/Application';
import linkedinLogo from '../../../../../images/logos/linkedin.svg';
import { getProviderLogo } from '../../../../../utils/providerLogo';
import { ImageLink } from '../ImageLink';
import { appendRedirectUri } from '../../../../../utils/url';
import { AnonymizedTooltip } from '../../../../../components/AnonymizedTooltip';

interface NameCellPropTypes {
  application: Application;
  interviewIntelligenceAccess: boolean;
  referenceCheckAccess: boolean;
}

export function NameCell(props: NameCellPropTypes) {
  return (
    <>
      <div className='row'>
        <div className='col-auto pe-1'>
          <div className='fw-semibold text-truncate'>
            {(props.interviewIntelligenceAccess ||
              props.referenceCheckAccess) &&
            !props.application.anonymized ? (
              <a
                href={appendRedirectUri(
                  `/applications/${props.application.id}`,
                )}
              >
                {props.application.candidate.name}
              </a>
            ) : (
              props.application.candidate.name
            )}
          </div>
        </div>
        {props.application.anonymized && (
          <div className='col-auto px-1'>
            <AnonymizedTooltip />
          </div>
        )}
        {props.application.provider && props.application.profileUrl && (
          <div className='col-auto px-1'>
            <ImageLink
              tooltipText={`Open application in ${props.application.provider}`}
              href={props.application.profileUrl}
              imageSrc={getProviderLogo(props.application.provider)}
            />
          </div>
        )}
        {props.application?.candidate?.linkedinProfileUrl && (
          <div className='col-auto px-1'>
            <ImageLink
              tooltipText='LinkedIn'
              href={props.application.candidate.linkedinProfileUrl}
              imageSrc={linkedinLogo}
            />
          </div>
        )}
      </div>
      <div className='fs-6 text-truncate'>
        {props.application.candidate.email}
      </div>
    </>
  );
}
