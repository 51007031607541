import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { UserList } from '../../../../entities/v1/User';
import { NoRecordingReasonList } from '../../../../entities/v1/interview_intelligence/NoRecordingReasonList';

export interface ListParams {
  interviewId: number;
  name?: string;
  page?: number;
  pageSize?: number;
  expand?: string[];
}

export class InterviewService {
  public static listShareUsers(params: ListParams): Promise<UserList> {
    return ApiService.get<UserList>(
      `/api/v1/interview_intelligence/interviews/${params.interviewId}/share_users`,
      snakecaseKeys(params),
    );
  }

  public static listNoRecordingReasons(
    params: ListParams,
  ): Promise<NoRecordingReasonList> {
    return ApiService.get<NoRecordingReasonList>(
      `/api/v1/interview_intelligence/interviews/${params.interviewId}/no_recording_reasons`,
    );
  }
}
