import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { getJobStageTriggers, TriggersIcon } from '../../TriggersIcon';
import { buildDropDownActions, JobStageDropdown } from './JobStageDropdown';
import { JobDetails } from '../../../../../entities/JobDetails';
import { ApplyAndScheduleModal } from '../ApplyAndScheduleModal';
import { PipelineJobStage } from '../../../../../entities/v1/applicant_tracking/PipelineJobStage';
import { SortingColumn } from '../../../../../utils/sorting';

interface PropTypes {
  jobDetails: JobDetails;
  pipelineJobStage: PipelineJobStage;
  totalApplications: number;
  applyAndScheduleLinkEnabled: boolean;
  selfSchedulingLinkOptionsEnabled: boolean;
  sortingColumn: SortingColumn;
}

export function JobStageHeader(props: PropTypes) {
  const jobStageTriggers = getJobStageTriggers(
    props.pipelineJobStage,
    props.jobDetails.triggers,
  );
  const jobStageHasTriggers = jobStageTriggers?.length > 0;

  const [modalOpen, setModalOpen] = useState(false);

  const dropDownActions = buildDropDownActions(
    props.jobDetails.id,
    props.pipelineJobStage,
    props.totalApplications,
    setModalOpen,
    props.applyAndScheduleLinkEnabled,
    props.selfSchedulingLinkOptionsEnabled,
    props.sortingColumn,
  );

  return (
    <div className={'d-flex align-items-center border-bottom'}>
      <h4
        className={classNames(
          'p-4',
          'mb-0',
          'text-truncate',
          'text-start',
          'bg-white',
          'fw-bold',
          styles['pipeline-job-stage-header'],
        )}
      >
        {props.pipelineJobStage.jobStage.name}
      </h4>
      <TriggersIcon
        className={dropDownActions.length == 0 && 'me-2h'}
        pipelineJobStage={props.pipelineJobStage}
        jobStageTriggers={jobStageTriggers}
      />
      <JobStageDropdown
        className={!jobStageHasTriggers && 'ms-auto'}
        jobId={props.jobDetails.id}
        pipelineJobStage={props.pipelineJobStage}
        menuActions={dropDownActions}
      />
      <ApplyAndScheduleModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        job={props.jobDetails}
        pipelineJobStage={props.pipelineJobStage}
      />
    </div>
  );
}
