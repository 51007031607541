import React, { ReactElement, useState } from 'react';
import { CandidateCard } from '../../CandidateCard';
import { MoveCandidateProps } from '../../../components/MoveCandidateStageModal';
import { HiringMember } from '../../../../../entities/HiringMember';
import { User } from '../../../../../entities/User';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { PipelineApplication } from '../../../../../entities/applicant_tracking/PipelineApplication';
import { JobState } from '../../../../../entities/JobDetails';
import { AlertObject, AlertType } from '../../../../../components/Alert';
import { AccountIntegration } from '../../../../../entities/AccountIntegration';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingSpinnerIconSpan } from '../../../../../components/LoadingSpinnerIconSpan';
import { RejectCandidateModal } from '../../../../CandidatePage/CandidateActions/DropdownActions/RejectCandidateModal';
import { ApiError } from '../../../../../services/ApiService/errors/ApiError';
import { SendEmailDto } from '../../../../../services/dtos/EmailDto';
import { SendEmailModal } from '../../../../SendEmailModal';
import { ApplicationService } from '../../../../../services/applicant_tracking/ApplicationService';
import { PipelineJobStage } from '../../../../../entities/v1/applicant_tracking/PipelineJobStage';

interface PropTypes {
  applications?: PipelineApplication[];
  currentHiringMember?: HiringMember;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  jobStatus: JobState;
  loadApplications: () => void;
  hasMoreApplications: boolean;
  offerEnabled?: boolean;
  onMoveCandidate?: (
    application: PipelineApplication,
    action?: MoveCandidateProps,
  ) => void;
  onRejectApplication: (applicationId: number) => void;
  pipelineJobStage: PipelineJobStage;
  setAlert: (alert: AlertObject) => void;
  totalApplications: number;
}

function getScrollableDivId(jobStageId: number) {
  return `scrollableDiv_${jobStageId}`;
}

function ApplicationsInfiniteScroll(
  props: PropTypes & { children: ReactElement },
) {
  if (!props.hasMoreApplications) {
    return props.children;
  }

  return (
    <InfiniteScroll
      className={classNames(
        'h-100 overflow-hidden',
        styles['application-list'],
      )}
      dataLength={props.applications.length}
      next={props.loadApplications}
      hasMore={props.hasMoreApplications}
      loader={
        <LoadingSpinnerIconSpan
          className={'mt-3'}
          iconClassName='text-blue-500'
          textClassName={'text-dark-200 ps-2 fs-6'}
          text={'Loading'}
        />
      }
      scrollableTarget={getScrollableDivId(props.pipelineJobStage.jobStageId)}
    >
      {props.children}
    </InfiniteScroll>
  );
}

export function JobStageApplications(props: PropTypes) {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
  const [actionableApplication, setActionableApplication] =
    useState<PipelineApplication>(null);

  const onSendEmail = async (sendEmailProps: SendEmailDto) => {
    if (!actionableApplication) return onCloseEmailModal();

    await ApplicationService.sendEmails(
      [actionableApplication?.id],
      sendEmailProps,
    )
      .then(() => {
        props.setAlert({
          message: 'This email was successfully sent.',
          type: AlertType.Success,
        });
      })
      .catch((e: ApiError) => {
        props.setAlert({
          message: e.body?.message || 'The email was not sent.',
          type: AlertType.Danger,
        });
      })
      .finally(() => onCloseEmailModal());
  };

  const handleRejectModalClose = async (sendEmail?: boolean) => {
    setIsRejectModalOpen(false);

    if (sendEmail) {
      setIsEmailModalOpen(true);
    }
  };

  const onCloseEmailModal = () => {
    setIsEmailModalOpen(false);
    setActionableApplication(null);
  };

  return (
    <div
      id={getScrollableDivId(props.pipelineJobStage.jobStageId)}
      className={classNames(
        'd-flex h-100 pb-2 pt-2h px-3 overflow-scroll flex-column',
        styles['application-list'],
      )}
    >
      <ApplicationsInfiniteScroll {...props}>
        <div className={'container'}>
          <div className={'row gap-2'}>
            {props.applications?.map(
              (application: PipelineApplication, index: number) => (
                <CandidateCard
                  key={index}
                  application={application}
                  currentStage={application.jobStage.name}
                  currentHiringMember={props.currentHiringMember}
                  currentUser={props.currentUser}
                  daysOnStage={application.daysInCurrentJobStage}
                  emailAuthorizeUrl={props.emailAccountIntegration.authorizeUrl}
                  name={application.candidate.name}
                  jobStatus={props.jobStatus}
                  offerEnabled={props.offerEnabled}
                  profileLink={`/applications/${application.id}`}
                  onMoveCandidate={props.onMoveCandidate}
                  setAlert={props.setAlert}
                  onRejectApplication={(application) => {
                    setActionableApplication(application);
                    setIsRejectModalOpen(true);
                  }}
                />
              ),
            )}
          </div>
        </div>
      </ApplicationsInfiniteScroll>
      <RejectCandidateModal
        applicationId={actionableApplication?.id}
        candidateName={actionableApplication?.candidate.name}
        jobName={actionableApplication?.job?.name}
        isOpen={isRejectModalOpen}
        onClose={handleRejectModalClose}
        onSuccess={props.onRejectApplication}
        sendEmailEnabled={props.currentUser.hasEmailConnected}
        user={props.currentUser}
      />
      {isEmailModalOpen && (
        <SendEmailModal
          applicationIds={[actionableApplication?.id]}
          toEmail={actionableApplication?.candidate.email}
          hasEmailConnected={props.currentUser.hasEmailConnected}
          isOpen={isEmailModalOpen}
          onCloseModal={onCloseEmailModal}
          onSendEmail={onSendEmail}
        />
      )}
    </div>
  );
}
