import React, { useEffect, useState } from 'react';
import { Alert, AlertObject, AlertType } from '../../../components/Alert';
import { appendQueryParam, getRedirectUriQueryParam } from '../../../utils/url';
import { IconSpan } from '../../../components/IconSpan';
import {
  APPLICATIONS_LOAD_LIMIT,
  ApplicationsContainer,
} from './ApplicationsContainer';
import {
  getApplicationIdsToBulkReview,
  getBulkReviewData,
} from '../../../utils/applicant_tracking/bulkApplicationReview';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { JobService } from '../../../services/v1/applicant_tracking/JobService';
import { redirectWithAlert } from '../../../services/FlashService';
import { ApplicationIdList } from '../../../entities/v1/ApplicationIdList';

export type BULK_REVIEW_NAVIGATION_TYPE = 'rejected' | 'moved' | 'skipped';

// The offset needs to be bigger than the APPLICATIONS_LOAD_LIMIT
const APPLICATION_IDS_LAZY_LOADING_OFFSET = APPLICATIONS_LOAD_LIMIT + 3;

function prepareRedirectUri(
  uri: string,
  rejectedCount: number,
  movedToNextStageCount: number,
  skippedCount: number,
): string {
  uri = appendQueryParam(uri, 'rejected', `${rejectedCount}`);
  uri = appendQueryParam(uri, 'moved', `${movedToNextStageCount}`);
  uri = appendQueryParam(uri, 'skipped', `${skippedCount}`);
  return uri;
}

export default function BulkApplicationReview() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [alert, setAlert] = useState<AlertObject>(null);
  const [preSelectedRejectReasonId, setPreSelectedRejectReasonId] =
    useState<number>(null);
  const [rejectedCount, setRejectedCount] = useState<number>(0);
  const [movedToNextStageCount, setMovedToNextStageCount] = useState<number>(0);
  const [exitedApplicationId, setExitedApplicationId] = useState<number>(null);
  const [applicationIds, setApplicationIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalApplications, setTotalApplications] = useState<number>(0);
  const [currentApplicationsPage, setCurrentApplicationsPage] =
    useState<ApplicationIdList>(null);

  const loadApplications = (page: number) => {
    const urlApplicationIds = getApplicationIdsToBulkReview();
    if (urlApplicationIds.length > 0) {
      setApplicationIds(urlApplicationIds);
      setTotalApplications(urlApplicationIds.length);
      setIsLoading(false);
      return;
    }

    const bulkReviewParams = getBulkReviewData();
    const sortingColumn = bulkReviewParams?.['sorting_column'];
    const jobId = bulkReviewParams?.['job_id'];
    const jobStageId = bulkReviewParams?.['job_stage_id'];

    if (!jobId) {
      redirectWithAlert(
        getRedirectUriQueryParam(),
        'It was not possible to review the candidates.',
        AlertType.Danger,
      );
    }

    JobService.listApplicationIds({
      id: jobId,
      jobStageId: jobStageId,
      page: page,
      pageSize: 10,
      statuses: ['active', 'hired'],
      sortColumn: sortingColumn.column_name,
      sortDirection: sortingColumn.direction,
    })
      .then((applicationIdList: ApplicationIdList) => {
        const retrievedApplicationIds = applicationIdList.applicationIds;
        if (page == 1) {
          setApplicationIds(retrievedApplicationIds);
          setTotalApplications(applicationIdList.entriesCount);
        } else {
          applicationIds.push(...retrievedApplicationIds);
        }
        setCurrentApplicationsPage(applicationIdList);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    window.location.href = prepareRedirectUri(
      getRedirectUriQueryParam(),
      rejectedCount,
      movedToNextStageCount,
      totalApplications - rejectedCount - movedToNextStageCount,
    );
  };

  const handleNext = (navType: BULK_REVIEW_NAVIGATION_TYPE) => {
    switch (navType) {
      case 'rejected':
        setRejectedCount((prevCount) => prevCount + 1);
        break;
      case 'moved':
        setMovedToNextStageCount((prevCount) => prevCount + 1);
        break;
    }
    setExitedApplicationId(applicationIds[currentIndex]);
    setCurrentIndex((prevIndex) => prevIndex + 1);

    if (
      currentApplicationsPage?.nextPage &&
      currentIndex + APPLICATION_IDS_LAZY_LOADING_OFFSET > applicationIds.length
    ) {
      loadApplications(currentApplicationsPage.nextPage);
    }
  };

  useEffect(() => {
    loadApplications(1);
  }, []);

  useEffect(() => {
    if (isLoading) return;

    if (currentIndex + 1 > applicationIds.length) {
      handleClose();
    }
  }, [currentIndex]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {alert && (
        <Alert
          clearable
          type={alert.type ?? AlertType.Success}
          autoClearTimeout={4000}
          onClose={() => {
            setAlert(null);
          }}
        >
          <IconSpan
            icon={{ name: 'bi-info-circle' }}
            spanText={alert.message}
            spanClassName='ms-3'
          />
        </Alert>
      )}
      <ApplicationsContainer
        applicationIds={applicationIds}
        currentIndex={currentIndex}
        preSelectedRejectReasonId={preSelectedRejectReasonId}
        exitedApplicationId={exitedApplicationId}
        onClose={handleClose}
        setAlert={setAlert}
        setPreSelectedRejectReasonId={setPreSelectedRejectReasonId}
        onNext={handleNext}
        totalApplications={totalApplications}
      />
    </>
  );
}
