import { Application } from '../../entities/Application';
import { ApiService } from '../ApiService';
import { InterviewListItem } from '../../entities/InterviewListItem';

export class ApplicationService {
  public static get(id: number): Promise<Application> {
    return ApiService.get<Application>(`/api/applications/${id}`);
  }

  public static listInterviews(
    applicationId: number,
  ): Promise<InterviewListItem[]> {
    return ApiService.get(`/api/applications/${applicationId}/interviews`);
  }
}
