import React from 'react';
import { Card } from '../../../components/Card';

export function Footer() {
  return (
    <div className='pt-4 text-center'>
      <Card>
        <p className='text-dark-200 fs-5'>
          We take security and privacy seriously. All of the data we collect is
          completely secure and confidential.
        </p>
        <p className='text-dark-200 fs-5 mb-0'>
          If you have any questions, please contact us at{' '}
          <a
            href='mailto:support@screenloop.com'
            target='_blank'
            rel='noreferrer'
          >
            support@screenloop.com
          </a>
        </p>
      </Card>
    </div>
  );
}
