import React from 'react';
import classNames from 'classnames';
import { Row } from 'reactstrap';
import { Pagination } from '../../../../../../../components/Pagination';
import { EmptyState } from '../../../../../../../components/EmptyState';
import UndrawEmptyState from '../../../../../../../images/undraw_empty_state_cactus.svg';

interface PropTypes {
  disabled: boolean;
  error: boolean;
  page: number;
  pageSize: number;
  totalRows: number;
  onPageChanged: (number) => void;
  className?: string;
  additionalInfo?: React.ReactNode;
}

function FooterCounter(props: PropTypes) {
  return (
    <ul className='col page-entries'>
      <span className='d-none d-sm-inline'>Showing </span>
      {(props.page - 1) * props.pageSize + 1}
      &nbsp;-&nbsp;
      {Math.min(props.page * props.pageSize, props.totalRows)} of{' '}
      {props.totalRows}
      {props.additionalInfo}
    </ul>
  );
}

function TableEmptyState(props: { error: boolean }) {
  let title = 'No results found';
  let text = 'Please refine your search or filters and try again.';

  if (props.error) {
    title = 'We have encountered an error';
    text =
      'Please try again. If the error persists contact our team at support@screenloop.com';
  }

  return <EmptyState title={title} text={text} imageSrc={UndrawEmptyState} />;
}

export function TableFooter(props: PropTypes) {
  if (props.totalRows === 0 || props.error) {
    return <TableEmptyState error={props.error} />;
  }

  const pageCount = Math.ceil(props.totalRows / props.pageSize);
  return (
    <Row
      className={classNames(
        'table-responsive-footer align-items-center fs-5',
        props.className,
      )}
    >
      {!props.disabled && <FooterCounter {...props} />}
      {
        <div className='col mb-3'>
          <Pagination
            page={props.page}
            pageCount={pageCount}
            visiblePageCount={3}
            disabled={props.disabled}
            onPageClicked={props.onPageChanged}
          />
        </div>
      }
    </Row>
  );
}
