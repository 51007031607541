import React from 'react';
import { Button } from 'reactstrap';
import { getRedirectUriQueryParam } from '../../../../../utils/url';
import { LoadingSpinnerIcon } from '../../../../../components/LoadingSpinnerIcon';

interface PropTypes {
  applicationId: number;
  disabled: boolean;
  editing: boolean;
  onSubmit: () => void;
}

export function SubmitScorecard(props: PropTypes) {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const onSubmit = () => {
    setIsSubmitting(true);
    props.onSubmit();
  };

  return (
    <div className='d-flex gap-3'>
      <Button
        color='danger'
        type='button'
        onClick={() =>
          (window.location.href =
            getRedirectUriQueryParam() ??
            `/applications/${props.applicationId}`)
        }
        value='cancel'
      >
        Cancel
      </Button>
      <Button
        color='primary'
        type='submit'
        onClick={onSubmit}
        value='submit'
        disabled={props.disabled || isSubmitting}
        style={{ minWidth: '60px' }}
      >
        {isSubmitting ? <LoadingSpinnerIcon /> : props.editing ? 'Save' : 'Add'}
      </Button>
    </div>
  );
}
