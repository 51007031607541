import React from 'react';
import { IconButton } from '../../../../../components/IconButton';
import { bulkJobStageApplicationReviewUrl } from '../../../../../utils/applicant_tracking/bulkApplicationReview';
import { SortingColumn } from '../../../../../utils/sorting';

interface PropTypes {
  jobId: number;
  jobStageId: number;
  sortingColumn: SortingColumn;
}

export function JobStageBulkReviewButton(props: PropTypes) {
  return (
    <div className='pt-2h d-flex justify-content-center'>
      <IconButton
        iconName='bi-people'
        buttonText='Review Candidates'
        color='borderless'
        size='sm'
        onClick={() =>
          (window.location.href = bulkJobStageApplicationReviewUrl(
            props.jobId,
            props.jobStageId,
            props.sortingColumn,
          ))
        }
      />
    </div>
  );
}
