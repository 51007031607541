import classNames from 'classnames';
import React from 'react';
import { Col } from 'reactstrap';
import { DropdownCandidate } from '../DropdownCandidate';
import {
  MoveCandidateProps,
  MoveCandidateStageModal,
} from '../../../components/MoveCandidateStageModal';
import { JobState } from '../../../../../entities/JobDetails';
import { useParams } from 'react-router';
import { HiringMember } from '../../../../../entities/HiringMember';
import { User } from '../../../../../entities/User';
import { getApplicationActionInfo } from '../../../../../utils/applicant_tracking/actions/applicationActions';
import { JobHiringMemberRole } from '../../../../../entities/JobHiringMemberRole';
import { PipelineApplication } from '../../../../../entities/applicant_tracking/PipelineApplication';
import { AlertObject } from '../../../../../components/Alert';
import { Icon } from '../../../../../components/Icon';
import { SpanTooltip } from '../../../../../components/SpanTooltip';

interface PropTypes {
  application: PipelineApplication;
  currentHiringMember?: HiringMember;
  currentStage: string;
  currentUser: User;
  daysOnStage?: number;
  emailAuthorizeUrl: string;
  jobStatus: JobState;
  name: string;
  profileLink: string;
  offerEnabled?: boolean;
  onMoveCandidate?: (
    application: PipelineApplication,
    action?: MoveCandidateProps,
  ) => void;
  setAlert: (alert: AlertObject) => void;
  onRejectApplication: (application: PipelineApplication) => void;
}

function daysOnStageColourClass(days: number): string {
  if (days <= 3) {
    return 'text-dark-200';
  } else if (days <= 7) {
    return 'text-orange';
  } else {
    return 'text-danger-500';
  }
}

export function NameAndDaysDetail(props: PropTypes) {
  const jobId = useParams().id;
  const [
    candidateMoveToStageModalVisibility,
    setCandidateMoveToStageModalVisibility,
  ] = React.useState<boolean>(false);

  const moveCandidateToStage = () => {
    setCandidateMoveToStageModalVisibility(true);
  };

  const handleMoveCandidateToStage = (actions: MoveCandidateProps) => {
    setCandidateMoveToStageModalVisibility(false);
    props.onMoveCandidate && props.onMoveCandidate(props.application, actions);
  };

  const jobHiringMemberRole = props.currentHiringMember
    ?.role as JobHiringMemberRole;

  return (
    <div className='d-flex flex-nowrap fs-6'>
      <Col xs='10' className='d-flex text-nowrap fs-6 fw-semibold text-start'>
        <span>
          <a
            onClick={() => window.open(props.profileLink, '_blank')}
            className='text-truncate text-blue'
          >
            {props.name}
          </a>
          {props.application.redacted && (
            <SpanTooltip
              className='ms-2h'
              text={
                <Icon className='text-blue-200 fs-6' name='bi-file-lock2' />
              }
              tooltipText='Candidate information has been redacted to reduce bias.'
            />
          )}
        </span>
      </Col>
      <Col
        xs='auto'
        className={classNames(
          'text-end',
          'fw-bold',
          'fs-5',
          'ms-auto',
          daysOnStageColourClass(props.daysOnStage),
        )}
      >
        <DropdownCandidate
          authorizeUrl={props.emailAuthorizeUrl}
          moveToStageActionInfo={getApplicationActionInfo(
            {
              status: props.application.status,
              job: { status: props.jobStatus },
            },
            props.currentUser.isAdmin,
            jobHiringMemberRole,
          )}
          profileLink={props.profileLink}
          moveCandidateToStage={moveCandidateToStage}
          application={props.application}
          currentUser={props.currentUser}
          jobHiringMemberRole={jobHiringMemberRole}
          offerEnabled={props.offerEnabled}
          setAlert={props.setAlert}
          onRejectApplication={props.onRejectApplication}
        />
      </Col>
      <MoveCandidateStageModal
        applicationId={props.application.id}
        isOpen={candidateMoveToStageModalVisibility}
        candidateName={props.name}
        currentStage={props.currentStage}
        jobId={jobId}
        onClose={handleMoveCandidateToStage}
      />
    </div>
  );
}
