import React from 'react';
import { ApplicationListingFilters } from '../../utils';
import { Col } from 'reactstrap';
import { LabelledDatePicker } from '../../../../../../../../components/LabelledDatePicker';
import { emptyDateRange } from '../../utils/DateRangeFilter';

interface PropTypes {
  isLoading: boolean;
  filters: ApplicationListingFilters;
  setFilters: (filters: ApplicationListingFilters) => void;
}

export function DateRangeFilter(props: PropTypes) {
  const dateRange = props.filters?.dateRange ?? emptyDateRange();

  return (
    <Col xs='3' className='mt-3h'>
      <LabelledDatePicker
        clearable
        label='Applied At'
        dateFilter={dateRange}
        className='w-100'
        labelClassName='fs-5 fw-semibold'
        placeholder='Select Time Period'
        iconName='bi-calendar-week'
        disabled={props.isLoading}
        onChange={(start, end, range) => {
          if (
            dateRange?.start !== start ||
            dateRange?.end !== end ||
            dateRange?.range !== range
          ) {
            props.setFilters({
              ...props.filters,
              dateRange: {
                start: start,
                end: end,
                range: range,
              },
            });
          }
        }}
      />
    </Col>
  );
}
