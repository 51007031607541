import React from 'react';
import { Size } from '../../utils/types/Size';
import { LoadingSpinnerIcon } from '../LoadingSpinnerIcon';
import classNames from 'classnames';

interface PropTypes {
  className?: string;
  iconSize?: Size;
  iconClassName?: string;
  text?: string;
  textClassName?: string;
}

export function LoadingSpinnerIconSpan(props: PropTypes) {
  return (
    <div
      className={classNames(
        props.className,
        'd-flex justify-content-center align-items-center',
      )}
    >
      <LoadingSpinnerIcon
        size={props.iconSize}
        className={props.iconClassName}
      />
      <span className={classNames(props.textClassName)}>
        {props.text || 'Loading'}
      </span>
    </div>
  );
}
