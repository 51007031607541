import React, { useEffect, useState } from 'react';
import { SetupPageHeader } from '../../../../components/SetupPageHeader';
import { Card } from '../../../../components/Card';
import { BackArrow } from '../../../../components/BackArrow';
import { PageTitle } from '../../../../components/PageTitle';
import { InterviewParticipants } from '../../Interviews/ScheduleInterviewPage/InterviewDetails/InterviewParticipants';
import { InterviewerParticipant } from '../../../../entities/applicant_tracking/InterviewerParticipant';
import { User } from '../../../../entities/User';
import {
  DEFAULT_DURATION_IN_MINUTES,
  InterviewDuration,
} from '../../Interviews/ScheduleInterviewPage/InterviewDetails/InterviewDuration';
import { InterviewMeetingProvider } from '../../Interviews/ScheduleInterviewPage/InterviewDetails/InterviewMeetingProvider';
import { VcsProvider } from '../../../../enums/IntegrationProvider/VcsProvider';
import classNames from 'classnames';
import { TimezoneSelect } from '../../../../components/TimezoneSelect';
import {
  DateRangeSelect,
  DEFAULT_DATE_RANGE,
} from '../../../../components/DateRangeSelect';
import {
  DEFAULT_SCHEDULE_FROM_HOURS,
  ScheduleFromSelect,
} from '../../Interviews/ScheduleInterviewPage/ScheduleFromSelect';
import {
  mapIntToScheduleFrom,
  mapScheduleFromToInt,
  ScheduleFromType,
} from '../../Interviews/ScheduleInterviewPage/ScheduleFromSelect/ScheduleFromTypes';
import { Col, Row } from 'reactstrap';
import {
  getAvailableMeetingProviders,
  getDefaultMeetingProvider,
} from '../../../../utils/applicant_tracking/interviews/interviews';
import { InterviewTemplate } from '../../../../entities/applicant_tracking/InterviewTemplate';
import { ActionType } from '../../../../enums/ActionType';
import { IconSpan } from '../../../../components/IconSpan';
import { InterviewTemplateService } from '../../../../services/v1/applicant_tracking/InterviewTemplateService';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { Alert, AlertObject, AlertType } from '../../../../components/Alert';
import { ErrorCodes } from '../../../../services/ApiService/errors/ErrorCodes';
import { InputFormField } from '../../../../components/InputFormField';

const BACK_URL =
  getRedirectUriQueryParam() ?? '/organization_settings/interview_templates';
const NAME_MAXIMUM_CHARACTERS = 32;

interface PropTypes {
  meetingOrganizer: User;
  availableParticipants: User[];
  interviewTemplate?: InterviewTemplate;
  actionType: ActionType;
}

enum FormField {
  Name = 'name',
}

function getParticipants(
  meetingOrganizer: User,
  interviewTemplate?: InterviewTemplate,
): InterviewerParticipant[] {
  if (!interviewTemplate) {
    return [
      {
        user: meetingOrganizer,
        isMeetingOrganizer: meetingOrganizer.hasCalendarConnected,
        ignoreCalendar: false,
      },
    ];
  }

  return interviewTemplate.participants.map((user) => ({
    user: user,
    isMeetingOrganizer: user.id === meetingOrganizer.id,
    ignoreCalendar: false,
  }));
}

function getMeetingProvider(
  meetingOrganizer: User,
  interviewTemplate?: InterviewTemplate,
): VcsProvider {
  if (!interviewTemplate) {
    return getDefaultMeetingProvider(meetingOrganizer);
  }

  return interviewTemplate.meetingProvider;
}

function handleApiError(
  error,
  setAlertObject: (alert: AlertObject) => void,
  setFormFieldWithError: (formField: FormField) => void,
  setSaveEnabled: (b: boolean) => void,
): void {
  const isNameInvalid = (e) => {
    return (
      e?.body?.code === ErrorCodes.INVALID_PARAMETER_VALUE &&
      e?.body?.param === 'name'
    );
  };

  const message = isNameInvalid(error)
    ? 'The scheduling template name has already been taken. Please change it and try again.'
    : 'An error occurred please contact support at support@screenloop.com';

  setAlertObject({
    message: message,
    type: AlertType.Danger,
  });

  setFormFieldWithError(isNameInvalid(error) && FormField.Name);

  setSaveEnabled(false);
}

export default function InterviewTemplatePage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertObject, setAlertObject] = useState<AlertObject>(null);
  const [actionType, setActionType] = useState<ActionType>(props.actionType);
  const [name, setName] = useState<string>(props.interviewTemplate?.name || '');
  const [formFieldWithError, setFormFieldWithError] = useState<FormField>(null);
  const [meetingOrganizer, setMeetingOrganizer] = useState<User>(
    props.meetingOrganizer,
  );
  const [participants, setParticipants] = useState<InterviewerParticipant[]>(
    getParticipants(props.meetingOrganizer, props.interviewTemplate),
  );
  const [durationMinutes, setDurationMinutes] = useState<number>(
    props.interviewTemplate?.meetingDuration || DEFAULT_DURATION_IN_MINUTES,
  );
  const [meetingProvider, setMeetingProvider] = useState<VcsProvider>(
    getMeetingProvider(props.meetingOrganizer, props.interviewTemplate),
  );
  const [availableMeetingProviders, setAvailableMeetingProviders] = useState<
    VcsProvider[]
  >(getAvailableMeetingProviders(props.meetingOrganizer));
  const [timezone, setTimezone] = useState<string>(
    props.interviewTemplate?.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const [dateRange, setDateRange] = useState<number>(
    props.interviewTemplate?.dateRange || DEFAULT_DATE_RANGE,
  );
  const [scheduleFrom, setScheduleFrom] = useState<ScheduleFromType>(
    mapIntToScheduleFrom(
      props.interviewTemplate?.scheduleFromHours || DEFAULT_SCHEDULE_FROM_HOURS,
    ),
  );
  const [isSaveEnabled, setSaveEnabled] = useState<boolean>(false);

  const onChangingMeetingOrganizer = (participant: InterviewerParticipant) => {
    setMeetingOrganizer(participant.user);
    setMeetingProvider(getDefaultMeetingProvider(participant.user));
    setAvailableMeetingProviders(
      getAvailableMeetingProviders(participant.user),
    );
  };

  const onEdit = () => {
    setActionType(ActionType.Edit);
  };

  const onCancel = () => {
    if (actionType === ActionType.Edit) {
      window.location.href =
        getRedirectUriQueryParam() ??
        `/organization_settings/interview_templates/${props.interviewTemplate?.id}`;
    } else {
      window.location.href = BACK_URL;
    }
  };

  async function onSave() {
    setIsLoading(true);

    try {
      await InterviewTemplateService.upsert({
        id: props.interviewTemplate?.id,
        name: name,
        participantUserIds: participants.map((p) => p.user.id),
        organizerUserId: participants.find((p) => p.isMeetingOrganizer).user.id,
        meetingDuration: durationMinutes,
        meetingProvider: meetingProvider,
        scheduleFromHours: mapScheduleFromToInt(scheduleFrom),
        dateRange: dateRange,
        timezone: timezone,
      });

      window.location.href =
        getRedirectUriQueryParam() ??
        '/organization_settings/interview_templates';
    } catch (e) {
      handleApiError(e, setAlertObject, setFormFieldWithError, setSaveEnabled);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const isValidName =
      name.length > 0 && name.length < NAME_MAXIMUM_CHARACTERS;
    setSaveEnabled(meetingOrganizer?.hasCalendarConnected && isValidName);
    setFormFieldWithError(null);
  }, [name, meetingOrganizer]);

  useEffect(() => {
    if (!meetingOrganizer?.hasCalendarConnected) {
      const participantWithCalendarConnected = participants.find(
        (p) => p.user?.hasCalendarConnected,
      );

      if (participantWithCalendarConnected) {
        participantWithCalendarConnected.isMeetingOrganizer = true;
        onChangingMeetingOrganizer(participantWithCalendarConnected);
      }
    }
  }, [participants]);

  const isReadOnly = actionType === ActionType.Show;

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      {alertObject && (
        <Alert
          type={alertObject.type}
          clearable={true}
          autoClearTimeout={4000}
          onClose={() => {
            setAlertObject(null);
          }}
        >
          {alertObject.message}
        </Alert>
      )}
      <div className='container'>
        <Title name={props.interviewTemplate?.name} />
        <Card>
          <CardHeader
            actionType={actionType}
            onEdit={onEdit}
            isSaveEnabled={isSaveEnabled}
            onSave={onSave}
            onCancel={onCancel}
            saveTooltip={
              !meetingOrganizer.hasCalendarConnected &&
              'Interview host does not have a connected calendar.'
            }
          />
          <div className={'w-50'}>
            <div className='d-flex flex-column pt-2 pb-1'>
              <InputFormField
                type={'text'}
                setValueOnChange={setName}
                fieldName={'Name'}
                placeholder={'Insert scheduling template name'}
                isRequired={true}
                maxLength={NAME_MAXIMUM_CHARACTERS}
                value={name}
                headerClassNames={'fw-semibold'}
                classNames={classNames(
                  formFieldWithError === FormField.Name && 'is-invalid',
                  'fs-5',
                )}
                disabled={isReadOnly}
                showMaxLengthText={true}
              />
            </div>
            <InterviewParticipants
              participants={participants}
              setParticipants={setParticipants}
              availableParticipants={props.availableParticipants}
              candidateView={false}
              allowChangingMeetingOrganizer={!isReadOnly}
              allowIgnoreParticipantCalendar={false}
              disableAlert={true}
              isRequired={true}
              onChangingMeetingOrganizer={onChangingMeetingOrganizer}
              titleClassName={'text-primary-dark fw-semibold'}
              isReadOnly={isReadOnly}
            />
            <Row className={'mt-3h'}>
              <Col sm={6}>
                <InterviewDuration
                  durationMinutes={durationMinutes}
                  setDurationMinutes={setDurationMinutes}
                  candidateView={false}
                  selectSize={'md'}
                  readOnly={isReadOnly}
                  titleClassName={'text-primary-dark fw-semibold'}
                />
              </Col>
              <Col sm={6}>
                <InterviewMeetingProvider
                  meetingProvider={meetingProvider}
                  setMeetingProvider={setMeetingProvider}
                  meetingProviders={availableMeetingProviders}
                  candidateView={false}
                  selectSize={'md'}
                  readOnly={isReadOnly}
                  titleClassName={'text-primary-dark fw-semibold'}
                />
              </Col>
            </Row>
            <Row className={'mt-3h'}>
              <Col sm={6}>
                <ScheduleFromSelect
                  scheduleFrom={scheduleFrom}
                  setScheduleFrom={setScheduleFrom}
                  titleClassName={'text-primary-dark fw-semibold fs-5'}
                  selectClassName={classNames('mt-2')}
                  selectSize={'md'}
                  toolTipText={
                    'This will allow scheduling from the moment the link is shared.'
                  }
                  readOnly={isReadOnly}
                />
              </Col>
              <Col sm={6}>
                <DateRangeSelect
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  titleClassName={'text-primary-dark fw-semibold fs-5'}
                  selectClassName={classNames('mt-2')}
                  selectSize={'md'}
                  readOnly={isReadOnly}
                />
              </Col>
            </Row>
            <Row className={'mt-3h'}>
              <Col sm={6}>
                <TimezoneSelect
                  timezone={timezone}
                  setTimezone={setTimezone}
                  titleClassName={'text-primary-dark fw-semibold fs-5'}
                  selectClassName={classNames('mt-2')}
                  selectSize={'md'}
                  readOnly={isReadOnly}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </>
  );
}

function Title(props: { name?: string }) {
  return (
    <div className='d-flex align-items-center mb-4'>
      <BackArrow url={BACK_URL} />
      <PageTitle text={props.name ?? 'Add Scheduling Template'} />
    </div>
  );
}

function CardHeader(props: {
  actionType: ActionType;
  onEdit: () => void;
  isSaveEnabled: boolean;
  onSave: () => void;
  onCancel: () => void;
  saveTooltip: string;
}) {
  if (props.actionType === ActionType.Show) {
    return <EditCardHeader onEdit={props.onEdit} />;
  }

  return (
    <SaveCardHeader
      isSaveEnabled={props.isSaveEnabled}
      onSave={props.onSave}
      onCancel={props.onCancel}
      saveTooltip={props.saveTooltip}
    />
  );
}

function EditCardHeader(props: { onEdit: () => void }) {
  return (
    <SetupPageHeader
      title={'Scheduling Template'}
      firstItems={[]}
      intermediateItems={[
        {
          title: <IconSpan spanText='Edit' icon={{ name: 'bi-pencil' }} />,
          type: 'Navigate',
          disabled: false,
          action: props.onEdit,
        },
      ]}
    />
  );
}

function SaveCardHeader(props: {
  isSaveEnabled: boolean;
  onSave: () => void;
  onCancel: () => void;
  saveTooltip: string;
}) {
  return (
    <SetupPageHeader
      title={'Scheduling Template'}
      firstItems={[]}
      intermediateItems={[
        {
          title: 'Cancel',
          type: 'Cancel',
          action: props.onCancel,
        },
        {
          title: 'Save',
          type: 'Save',
          disabled: !props.isSaveEnabled,
          action: props.onSave,
          tooltip: props.saveTooltip,
        },
      ]}
    />
  );
}
