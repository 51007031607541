import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { InterviewCandidateUser } from '../../../../../entities/applicant_tracking/InterviewCandidateUser';
import { Alert, AlertType } from '../../../../../components/Alert';
import { MINUTES_IN_DAY, Timedelta } from '../../../../../utils/timedelta';
import { SpanTooltip } from '../../../../../components/SpanTooltip';
import { SendEmailModal } from '../../../../SendEmailModal';
import { SendEmailDto } from '../../../../../services/dtos/EmailDto';
import { User } from '../../../../../entities/User';
import { InterviewCandidateUserService } from '../../../../../services/applicant_tracking/InterviewCandidateUserService';
import { ApiError } from '../../../../../services/ApiService/errors/ApiError';

interface PropTypes {
  candidateName: string;
  currentUser: User;
  interviewCandidateUser: InterviewCandidateUser;
}

function buildLink(props: PropTypes) {
  return `${window.location.origin}/applicant_tracking/interview_candidate_users/${props.interviewCandidateUser.secureId}/schedule`;
}

function EmailButton(props: { disabled: boolean; onClick: () => void }) {
  return (
    <Button
      type='button'
      color='primary'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      Send email to candidate
    </Button>
  );
}

function SendEmailButton(props: {
  emailEnabled: boolean;
  onClick: () => void;
}) {
  return (
    <>
      {props.emailEnabled ? (
        <EmailButton onClick={props.onClick} disabled={false} />
      ) : (
        <SpanTooltip
          text={<EmailButton onClick={null} disabled={true} />}
          tooltipText={'This email has already been sent.'}
          trigger='hover'
        />
      )}
    </>
  );
}

export default function ShareSelfScheduleOptions(props: PropTypes) {
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(AlertType.Success);
  const [emailEnabled, setEmailEnabled] = useState<boolean>(
    props.interviewCandidateUser.emailSentAt === null ||
      Timedelta.timeTillDate(
        props.interviewCandidateUser.emailSentAt,
      ).toMins() < -MINUTES_IN_DAY,
  );
  const onCopy = () => {
    navigator.clipboard.writeText(buildLink(props));
    setAlertType(AlertType.Success);
    setAlertMessage('The link has been successfully copied to your clipboard.');
  };

  const onSendEmail = async (sendEmailProps: SendEmailDto) => {
    await InterviewCandidateUserService.sendSelfScheduleEmails(
      [props.interviewCandidateUser.id],
      sendEmailProps,
    )
      .then(() => {
        setAlertType(AlertType.Success);
        setAlertMessage(
          'The email has been successfully sent to the candidate.',
        );
        setEmailEnabled(false);
      })
      .catch((e: ApiError) => {
        setAlertType(AlertType.Danger);
        setAlertMessage(
          e.body?.message || 'The email could not be sent to the candidate.',
        );
      })
      .finally(() => {
        setSendEmailModalOpen(false);
      });
  };

  return (
    <>
      {alertMessage && (
        <Alert
          type={alertType}
          clearable={true}
          autoClearTimeout={4000}
          onClose={() => {
            setAlertMessage(null);
            setAlertType(AlertType.Success);
          }}
        >
          {alertMessage}
        </Alert>
      )}
      <div className='d-flex justify-content-center mt-3'>
        <Button
          type='button'
          color='secondary'
          className='me-3'
          onClick={onCopy}
        >
          Copy self-scheduling link
        </Button>
        <SendEmailButton
          emailEnabled={emailEnabled}
          onClick={() => setSendEmailModalOpen(true)}
        />
      </div>
      <SendEmailModal
        applicationIds={[props.interviewCandidateUser.applicationId]}
        toEmail={props.interviewCandidateUser.email}
        hasEmailConnected={props.currentUser.hasEmailConnected}
        isOpen={sendEmailModalOpen}
        onCloseModal={() => setSendEmailModalOpen(false)}
        onSendEmail={onSendEmail}
      />
    </>
  );
}
