import { QuestionTopChoices } from '../../../../entities/candidate_experience/QuestionTopChoices';
import { Col, Row } from 'reactstrap';
import {
  TopChoices,
  TopChoicesChartType,
} from '../../shared_panels/TopChoices';
import stylesheetExports from '../../../../stylesheets/export.module.scss';
import React from 'react';
import classNames from 'classnames';
import { REPORTING_SECTION_CLASS } from '../../../../utils/pdfHandler';
import { PageBreak } from '../PageBreak';

export function InterestTopChoices(props: {
  companyInterest: QuestionTopChoices;
  jobInterest: QuestionTopChoices;
}) {
  if (props.companyInterest == null) return null;
  if (props.companyInterest.top_choices.length === 0) return null;

  return (
    <>
      <PageBreak />
      <Row className={classNames(REPORTING_SECTION_CLASS)}>
        <Col md='6'>
          <TopChoices
            title='Company Interest'
            subtitle={props.companyInterest.question_text}
            topChoices={props.companyInterest.top_choices}
            fillColor={stylesheetExports.green700}
            type={TopChoicesChartType.Circle}
          />
        </Col>
        <Col md='6'>
          <TopChoices
            title='Job Interest'
            subtitle={props.jobInterest.question_text}
            topChoices={props.jobInterest.top_choices}
            fillColor={stylesheetExports.chartOrange}
            type={TopChoicesChartType.Circle}
          />
        </Col>
      </Row>
    </>
  );
}
