import React, { useCallback } from 'react';
import { Application as DeprecatedApplication } from '../../../entities/Application';
import { Alert, AlertObject, AlertType } from '../../../components/Alert';
import { CandidatePageHeaderInfo } from './CandidatePageHeaderInfo';
import { Application } from '../../../entities/v1/applicant_tracking/Application';

interface PropTypes {
  alert?: AlertObject;
  application: DeprecatedApplication | Application;
  applicantTrackingEnabled: boolean;
  backUrl?: string;
  children?: JSX.Element;
  setAlert?: (alert: AlertObject) => void;
}

export function CandidatePageHeader(props: PropTypes) {
  const defaultAlert = { message: null, type: AlertType.Success };
  const onCloseAlert = useCallback(async () => {
    props.setAlert && props.setAlert(defaultAlert);
  }, []);

  return (
    <div className='d-flex justify-content-between'>
      <div className='d-flex align-items-center' data-testid='candidate-data'>
        {props.alert?.message && (
          <Alert
            type={props.alert?.type}
            clearable={true}
            autoClearTimeout={3000}
            onClose={onCloseAlert}
          >
            {props.alert?.message}
          </Alert>
        )}
        <CandidatePageHeaderInfo
          application={props.application}
          applicantTrackingEnabled={props.applicantTrackingEnabled}
          backUrl={props.backUrl}
        />
      </div>
      {props.children}
    </div>
  );
}
