import React from 'react';
import { Header } from './Header';
import { PersonalDetailsForm } from './PersonalDetailsForm';
import { UseFormRegister } from 'react-hook-form';
import { ShortApplication } from './ShortApplication';

interface PropTypes {
  register: UseFormRegister<ShortApplication>;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  isSubmitting: boolean;
}

export function PersonalDetailsStep(props: PropTypes) {
  return (
    <div>
      <Header
        title='Hello and welcome!'
        subtitle='Add your name and email address so we can share the invitation to the interview.'
      />
      <PersonalDetailsForm
        register={props.register}
        isSubmitting={props.isSubmitting}
        onSubmit={props.onSubmit}
      />
    </div>
  );
}
