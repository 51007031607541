import snakecaseKeys from 'snakecase-keys';
import { ApplyAndScheduleLinkSetting } from '../../../../entities/v1/applicant_tracking/ApplyAndScheduleLinkSetting';
import { ApiService } from '../../../ApiService';

export class ApplyAndScheduleLinkSettingService {
  public static show(secureId: string): Promise<ApplyAndScheduleLinkSetting> {
    return ApiService.get<ApplyAndScheduleLinkSetting>(
      `/api/v1/applicant_tracking/apply_and_schedule_link_settings`,
      snakecaseKeys({ secureId }),
    );
  }
}
