import React from 'react';
import { BackArrow } from '../../../../components/BackArrow';
import linkedinLogo from '../../../../images/logos/linkedin.svg';
import { PageTitle } from '../../../../components/PageTitle';
import { CandidateHeaderBadge } from '../CandidateHeaderBadge';
import { CandidatePageHeaderJobInfo } from '../CandidatePageHeaderJobInfo';
import { CandidatePageHeaderStatusInfo } from '../CandidatePageHeaderStatusInfo';
import { Application } from '../../../../entities/v1/applicant_tracking/Application';
import { Application as DeprecatedApplication } from '../../../../entities/Application';
import { Candidate } from '../../../../entities/v1/applicant_tracking/Candidate';
import { Candidate as DeprecatedCandidate } from '../../../../entities/Candidate';
import { CandidatePageHeaderEmployeeExternalURLInfo } from '../CandidatePageHeaderEmployeeExternalURLInfo';
import { Chip, ChipColor } from '../../../../components/Chip';
import { IconSpan } from '../../../../components/IconSpan';

interface PropTypes {
  application?: Application | DeprecatedApplication;
  applicantTrackingEnabled: boolean;
  backUrl?: string;
}

function getCandidateLinkedinUrl(candidate?: Candidate | DeprecatedCandidate) {
  if (candidate == null) return null;

  return (
    (candidate as Candidate)?.linkedinProfileUrl ??
    (candidate as DeprecatedCandidate)?.linkedin_profile_url
  );
}

export function CandidatePageHeaderInfo(props: PropTypes) {
  if (props.application == null) return null;

  return (
    <>
      <BackArrow url={props.backUrl || '/candidates'} />
      <PageTitle text={props.application.candidate.name} />
      {getCandidateLinkedinUrl(props.application.candidate) &&
        !props.applicantTrackingEnabled && (
          <a
            href={getCandidateLinkedinUrl(props.application.candidate)}
            target='_blank'
            className='ps-2h mb-1'
            rel='noreferrer'
          >
            <img src={linkedinLogo} style={{ maxHeight: '20px' }} />
          </a>
        )}
      {props.application.source?.channelType === 'employee_referrals' && (
        <CandidateHeaderBadge
          text='Referral'
          classNames='ms-2h'
          chipColor={ChipColor.Blue}
        />
      )}
      <CandidatePageHeaderStatusInfo
        status={props.application.status}
        classNames={'ms-2h'}
      />
      <CandidatePageHeaderEmployeeExternalURLInfo
        hrisEmployee={props.application.hrisEmployee}
      />
      {props.application.job && (
        <CandidatePageHeaderJobInfo
          jobId={props.application.job?.id}
          jobName={props.application.job?.name}
        />
      )}
      {(props.application as DeprecatedApplication)?.redacted && (
        <Chip className='ms-2h' color={ChipColor.White}>
          <IconSpan
            className='text-primary fw-semibold'
            spanText='Redacted'
            icon={{ className: 'fs-4 text-dark-200', name: 'bi-file-lock2' }}
          />
        </Chip>
      )}
    </>
  );
}
