import React, { useState } from 'react';
import { Card } from '../../../../components/Card';
import LogoImage from '../../../../images/logo.svg';
import { Footer } from '../Footer';
import { PersonalDetailsStep } from '../PersonalDetailsStep';
import { ApplicationDetailStep } from '../ApplicationDetailStep';
import {
  FormState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useForm,
} from 'react-hook-form';
import { ShortApplication } from '../ShortApplication';
import { ApplyAndScheduleLinkService } from '../../../../services/v1/applicant_tracking/ApplyAndScheduleLinkService';
import { ApplyAndScheduleLinkSettingService } from '../../../../services/v1/applicant_tracking/ApplyAndScheduleLinkSettingService';
import { ApplyAndScheduleLinkSetting } from '../../../../entities/v1/applicant_tracking/ApplyAndScheduleLinkSetting';

export type Step = 1 | 2;

interface PropTypes {
  secureId: string;
}

export default function ShowPage(props: PropTypes) {
  const [step, setStep] = useState<Step>(1);
  const { register, getValues, setValue, handleSubmit, formState } =
    useForm<ShortApplication>({ mode: 'onChange' });
  const [settings, setSettings] = useState<ApplyAndScheduleLinkSetting>(null);

  async function createApplication(form: ShortApplication) {
    try {
      const icu = await ApplyAndScheduleLinkService.createApplication(
        props.secureId,
        form.firstName,
        form.lastName,
        form.email,
        form.resumeContent,
        form.linkedinUrl,
      );
      window.location.href = `/applicant_tracking/interview_candidate_users/${icu.secureId}/schedule`;
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchSettings(): Promise<ApplyAndScheduleLinkSetting> {
    try {
      return await ApplyAndScheduleLinkSettingService.show(props.secureId);
    } catch (e) {
      if (e.status != 404) {
        console.error(e);
        return null;
      }
      return { resume: 'optional', linkedinUrl: 'hidden' };
    }
  }

  async function onSubmit(form: ShortApplication) {
    if (step === 1) {
      const application = await ApplyAndScheduleLinkService.showApplication(
        props.secureId,
        form.email,
      );
      if (application == null) {
        const settings = await fetchSettings();

        if (settings.resume == 'hidden' && settings.linkedinUrl == 'hidden') {
          await createApplication(form);
          return;
        }

        setSettings(settings);
        setStep(2);
        return;
      }
    }

    await createApplication(form);
  }

  return (
    <main className='mx-auto' style={{ maxWidth: '484px' }}>
      <div className='py-4'>
        <img
          src={LogoImage}
          alt='Screenloop Logo'
          className='d-block mx-auto'
          style={{ maxWidth: '183px' }}
        />
      </div>
      <Card className='p-5'>
        <StepSwitcher
          register={register}
          getValues={getValues}
          setValue={setValue}
          onSubmit={handleSubmit(onSubmit)}
          formState={formState}
          step={step}
          setStep={setStep}
          settings={settings}
        />
      </Card>
      <Footer />
    </main>
  );
}

interface StepSwitcherProps {
  register: UseFormRegister<ShortApplication>;
  getValues: UseFormGetValues<ShortApplication>;
  setValue: UseFormSetValue<ShortApplication>;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  formState: FormState<ShortApplication>;
  step: Step;
  setStep: (n: Step) => void;
  settings: ApplyAndScheduleLinkSetting;
}

function StepSwitcher(props: StepSwitcherProps) {
  switch (props.step) {
    case 1:
      return (
        <PersonalDetailsStep
          register={props.register}
          onSubmit={props.onSubmit}
          isSubmitting={props.formState.isSubmitting}
        />
      );
    case 2:
      return (
        <ApplicationDetailStep
          register={props.register}
          getValues={props.getValues}
          setValue={props.setValue}
          onSubmit={props.onSubmit}
          isValid={props.formState.isValid}
          setStep={props.setStep}
          settings={props.settings}
        />
      );
  }
}
