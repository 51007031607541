export enum BulkSelfSchedulingType {
  Applications = 'applications',
  JobStage = 'job_stage',
}

export interface BulkSelfSchedulingDto {
  applicationIds?: number[];
  applicationInterviewCandidateUsers?: {
    applicationId: number;
    interviewCandidateUserId: number;
  }[];
  totalApplications: number;
  type: BulkSelfSchedulingType;
}
