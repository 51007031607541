import React from 'react';

import { EditorState } from 'lexical';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ExportHtmlPlugin } from '../../../plugins/ExportHtmlPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import LinkPlugin from '../../../plugins/LinkPlugin';
import FloatingLinkEditorPlugin from '../../../plugins/FloatingLinkEditorPlugin';
import LexicalAutoLinkPlugin from '../../../plugins/LexicalAutoLinkPlugin';

interface PropTypes {
  disabled?: boolean;
  externalPlugins?: JSX.Element[];
  onChangeJson?: (state: any) => void;
  onChangeHtml?: (state: any) => void;
  floatingAnchorElem: HTMLDivElement | null;
  isLinkEditMode: boolean;
  setIsLinkEditMode: (v: boolean) => void;
}

export function PluginLoader(props: PropTypes) {
  return (
    <>
      {props.externalPlugins}
      {!props.disabled && (
        <>
          <ExportHtmlPlugin onChangeText={props.onChangeHtml} />
          {props.floatingAnchorElem && (
            <FloatingLinkEditorPlugin
              anchorElem={props.floatingAnchorElem}
              isLinkEditMode={props.isLinkEditMode}
              setIsLinkEditMode={props.setIsLinkEditMode}
            />
          )}
          <HistoryPlugin />
          <LexicalAutoLinkPlugin />
          <LinkPlugin />
          <ListPlugin />
          <OnChangePlugin
            ignoreHistoryMergeTagChange={true}
            ignoreSelectionChange={true}
            onChange={(editorState: EditorState) => {
              const updatedState = JSON.stringify(editorState.toJSON());

              props.onChangeJson?.(updatedState);
            }}
          />
          <TabIndentationPlugin />
        </>
      )}
    </>
  );
}
