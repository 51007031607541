import React, { useEffect, useRef, useState } from 'react';
import { NoteInput } from '../../NoteInput';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '../../../config/queryClient';
import styles from './styles.module.scss';

interface ModalProps {
  mode: 'Add' | 'Edit';
  initNote?: string;
  onSubmit: (note: string) => Promise<void>;
  onClose: (succeeded?: boolean) => void;
  customValidator?: (note: string) => boolean;
}

export const ATS_MAX_NOTE_LENGTH = 2048;

export function ModalBody(props: ModalProps) {
  const [note, setNote] = useState<string>(props.initNote ?? '');
  const inputRef = useRef(null);

  const addNote = async () => {
    try {
      await props.onSubmit(note);
      props.onClose(true);
    } catch (error) {
      props.onClose(false);
      console.error(error);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className='fw-semibold'>
      <div className='fs-5 d-flex justify-content-between align-items-start mb-2'>
        <span className='text-primary'>Note</span>
        <span
          className={classNames(
            'fs-6',
            note.length > ATS_MAX_NOTE_LENGTH ? 'text-danger' : 'text-dark-200',
          )}
        >
          {note.length}/{ATS_MAX_NOTE_LENGTH}
        </span>
      </div>
      <QueryClientProvider client={queryClient}>
        <NoteInput
          value={note}
          name='CandidateNote'
          placeholder='Add a note and/or @mention anyone from your team.'
          autofocus={true}
          appendSpaceOnAdd={true}
          className={classNames('p-2', 'fs-5', styles.noteText)}
          onChange={setNote}
          inputRef={inputRef}
        />
      </QueryClientProvider>
      <div className='d-flex mt-4 justify-content-end'>
        <Button color='danger' className='me-2' onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button
          disabled={
            note.length === 0 ||
            note.length > ATS_MAX_NOTE_LENGTH ||
            props.customValidator?.(note)
          }
          color='primary'
          onClick={addNote}
        >
          {props.mode === 'Add' ? 'Add' : 'Save'}
        </Button>
      </div>
    </div>
  );
}
