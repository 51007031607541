import React from 'react';
import { Button } from 'reactstrap';

interface ModalProps {
  candidateName: string;
  jobName?: string;
  onClose: (sendEmail: boolean) => void;
}

export function SuccessfulModalBody(props: ModalProps) {
  return (
    <>
      <div className='mb-4 fs-5'>
        <b>{props.candidateName}</b> was successfully rejected for the{' '}
        <b>{props.jobName}</b> job.{' '}
        <span className='fw-semibold'>
          Do you want to write and send an email to the candidate?
        </span>
      </div>
      <div className='d-flex mt-5 justify-content-end'>
        <Button
          color='secondary'
          className='me-2'
          onClick={() => props.onClose(false)}
        >
          Close
        </Button>
        <Button color='primary' onClick={() => props.onClose(true)}>
          Write Email
        </Button>
      </div>
    </>
  );
}
