import snakecaseKeys from 'snakecase-keys';
import { ApplyAndScheduleLink } from '../../../../entities/v1/applicant_tracking/ApplyAndScheduleLink';
import { ApiService } from '../../../ApiService';
import { Application } from '../../../../entities/v1/applicant_tracking/Application';
import { InterviewCandidateUser } from '../../../../entities/v1/applicant_tracking/InterviewCandidateUser';

export class ApplyAndScheduleLinkService {
  public static create(
    jobId: number,
    jobStageId: number,
    interviewTemplateId: number,
    expand: string[] = [],
  ): Promise<ApplyAndScheduleLink> {
    return ApiService.post<ApplyAndScheduleLink>(
      `/api/v1/applicant_tracking/apply_and_schedule_links`,
      snakecaseKeys({ jobId, jobStageId, interviewTemplateId, expand }),
    );
  }

  public static showApplication(
    secureId: string,
    email: string,
  ): Promise<Application> {
    return ApiService.get<Application>(
      `/api/v1/applicant_tracking/apply_and_schedule_links/applications`,
      snakecaseKeys({ secureId, email }),
    );
  }

  public static createApplication(
    secureId: string,
    firstName: string,
    lastName: string,
    email: string,
    resumeContent?: string,
    linkedinUrl?: string,
  ): Promise<InterviewCandidateUser> {
    return ApiService.post<InterviewCandidateUser>(
      `/api/v1/applicant_tracking/apply_and_schedule_links/applications`,
      snakecaseKeys({
        secureId,
        firstName,
        lastName,
        email,
        resumeContent,
        linkedinUrl,
      }),
    );
  }
}
