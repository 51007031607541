import React from 'react';
import { Chip, ChipColor } from '../../../../../components/Chip';
import { TruncateText } from '../../../../../components/TruncateText';
import { ClearIcon } from '../../../../../components/ClearIcon';
import { ApplicationTag } from '../../../../../entities/v1/applicant_tracking/ApplicationTag';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  applicationTag: ApplicationTag;
  onDelete: (id: number) => void;
  upsertTagsEnabled?: boolean;
}

const MAX_CHAR_LIMIT = 8;

export function TagChip(props: PropTypes) {
  return (
    <Chip color={ChipColor.BlueLight}>
      <div className='d-flex align-items-center'>
        <TruncateText
          tooltip
          ellipsize
          maxChars={MAX_CHAR_LIMIT}
          text={props.applicationTag.tag.name}
        />
        {props.upsertTagsEnabled && (
          <ClearIcon
            classNames={classNames('d-flex ms-1', styles['clear-icon'])}
            onClick={() => props.onDelete(props.applicationTag.id)}
          />
        )}
      </div>
    </Chip>
  );
}
