import React from 'react';
import { Application } from '../../../../entities/v1/Application';
import classNames from 'classnames';
import { shortFormatDate } from '../../../../utils/timeFormat';
import { jobStageChipColor } from '../../../../utils/jobStageChipColor';
import { NameCell } from './NameCell';
import { SurveyRequestStatusCell } from './SurveyRequestStatusCell';
import { ColumnPropTypes } from '..';
import { appendRedirectUri } from '../../../../utils/url';

interface PropTypes {
  applications: Application[];
  interviewIntelligenceAccess: boolean;
  referenceCheckAccess: boolean;
  columns: ColumnPropTypes[];
}

interface CandidateRowPropTypes {
  application: Application;
  interviewIntelligenceAccess: boolean;
  referenceCheckAccess: boolean;
  columns: ColumnPropTypes[];
}

function CandidateRow(props: CandidateRowPropTypes) {
  function columnVisible(column) {
    return !props.columns.find((c) => c.key == column)?.hidden;
  }

  return (
    <tr>
      {columnVisible('name') && (
        <td className='cell left-col'>
          <NameCell {...props} />
        </td>
      )}

      {columnVisible('job_name') && (
        <td className='cell'>
          <div className='text-truncate'>{props.application.job?.name}</div>
        </td>
      )}

      {columnVisible('applied_at') && (
        <td className='cell'>
          <div className='text-truncate'>
            {props.application.appliedAt &&
              shortFormatDate(props.application.appliedAt)}
          </div>
        </td>
      )}

      {columnVisible('job_stage_name') && (
        <td className='cell'>
          {props.application.jobStage ? (
            <span
              className={classNames(
                'badge rounded-pill me-1 fw-normal',
                jobStageChipColor(props.application.jobStage.name),
              )}
            >
              {props.application.jobStage.name}
            </span>
          ) : (
            <span className='text-center d-block'>-</span>
          )}
        </td>
      )}

      {columnVisible('survey_request_status') && (
        <td className='cell'>
          <SurveyRequestStatusCell {...props} />
        </td>
      )}

      {columnVisible('actions') && (
        <td className='cell right-col text-center'>
          {!props.application.anonymized && (
            <a
              className='btn btn-sm btn-secondary'
              href={appendRedirectUri(`/applications/${props.application.id}`)}
            >
              View
            </a>
          )}
        </td>
      )}
    </tr>
  );
}

export function TableBody(props: PropTypes) {
  return (
    <tbody>
      {props.applications.map((application, idx) => {
        return (
          <CandidateRow
            key={idx}
            application={application}
            interviewIntelligenceAccess={props.interviewIntelligenceAccess}
            referenceCheckAccess={props.referenceCheckAccess}
            columns={props.columns}
          />
        );
      })}
    </tbody>
  );
}
