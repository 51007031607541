import React, { useEffect, useState } from 'react';
import { FormState } from 'react-hook-form';
import { Button } from 'reactstrap';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { Application } from '../../../../../entities/applicant_tracking/Application';
import { Candidate } from '../../../../../entities/applicant_tracking/Candidate';
import { JobPost } from '../../../../../entities/applicant_tracking/JobPost';
import { Source } from '../../../../../entities/applicant_tracking/Source';
import { CandidateDto } from '../../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { JobPostService } from '../../../../../services/applicant_tracking/JobPostService';
import { SourceService } from '../../../../../services/v1/applicant_tracking/SourceService';
import { ApplicationForm } from '../../../Applications/ApplicationForm';
import { SubmitButton } from '../../../../../components/SubmitButton';
import { getRedirectUriQueryParam } from '../../../../../utils/url';
import { redirectWithAlert } from '../../../../../services/FlashService';

interface PropTypes {
  candidate: Candidate;
  dto: CandidateDto;
  referrerUserId?: number;
  onStep: (n: number) => void;
}

export function JobApplicationStep(props: PropTypes) {
  const [jobPosts, setJobPosts] = useState<JobPost[]>(null);
  const [sources, setSources] = useState<Source[]>(null);

  useEffect(() => {
    JobPostService.list({
      excludingCandidateId: props.candidate.id,
      restrictedAccess: true,
    }).then((jobPosts) => setJobPosts(jobPosts));
    SourceService.list({ pageSize: 100 }).then((sourceList) =>
      setSources(sourceList.sources),
    );
  }, []);

  async function handleSuccessfulSubmit(
    application: Application,
    referrerUserId: number,
    candidate: Candidate,
  ) {
    if (referrerUserId) {
      const alertMessage = `The referral **${
        candidate.firstName + candidate.lastName
      }** was successfully added!`;
      return redirectWithAlert('/applicant_tracking/referrals', alertMessage);
    }

    window.location.href = `/applications/${application.id}`;
  }

  return (
    <ApplicationForm
      candidateId={props.candidate.id}
      candidateDto={props.dto}
      jobPosts={jobPosts}
      sources={sources}
      referrerUserId={props.referrerUserId}
      onSuccessfulSubmit={(application) =>
        handleSuccessfulSubmit(
          application,
          props.referrerUserId,
          props.candidate,
        )
      }
      headerFactory={(formState) => (
        <FormHeader
          formState={formState}
          redirectUrl={getRedirectUriQueryParam() ?? '/candidates'}
          onStep={props.onStep}
        />
      )}
    />
  );
}

function FormHeader(props: {
  formState: FormState<CandidateDto>;
  redirectUrl: string;
  onStep: (n: number) => void;
}) {
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex'>
        <h2>Candidate Profile</h2>
        <div className='ms-auto d-flex gap-3'>
          <Button
            className='text-info border-info'
            onClick={() => props.onStep(0)}
          >
            Previous
          </Button>
          <VerticalDivider />
          <Button
            color='danger'
            onClick={() => (window.location.href = props.redirectUrl)}
          >
            Cancel
          </Button>
          <SubmitButton formState={props.formState} value='save_and_submit'>
            Save and Submit
          </SubmitButton>
        </div>
      </div>
    </div>
  );
}
