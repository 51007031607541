import React from 'react';
import ThreePeopleImage from '../../../images/mailer-new-survey-reference.png';

interface PropTypes {
  title: string;
  subtitle: string;
}

export function Header(props: PropTypes) {
  return (
    <>
      <img
        src={ThreePeopleImage}
        alt='Image with three people in it'
        className='d-block mx-auto'
        style={{ maxWidth: '179px' }}
      />
      <div className='text-center py-4'>
        <h2>{props.title}</h2>
        <p className='text-dark-200 fs-5 mb-0'>{props.subtitle}</p>
      </div>
    </>
  );
}
