import React from 'react';
import { ListApplicationsTable } from './ListApplicationsTable';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { User } from '../../../../../entities/User';
import { HrisEmployee } from '../../../../../entities/applicant_tracking/HrisEmployees';
import { HrisProvider } from '../../../../../enums/IntegrationProvider/HrisProvider';
import { AlertObject } from '../../../../../components/Alert';
import { EmailAccountIntegration } from '../../../../../entities/EmailAccountIntegration';
import { ListApplicationsFilters } from './ListApplicationsFilters';
import {
  ApplicationListingFilters,
  FilterType,
} from './ListApplicationsFilters/utils';
import { NewListCandidate } from '../../../../../entities/v1/applicant_tracking/NewListCandidate';

interface PropTypes {
  candidates: NewListCandidate[];
  currentUser: User;
  emailAccountIntegration: EmailAccountIntegration;
  error: boolean;
  filterStates: ApplicationListingFilters;
  filterTypes: FilterType[];
  hrisEmployees: HrisEmployee[];
  totalFilteredCandidates: number;
  selectedCandidates: NewListCandidate[];
  hrisProvider?: HrisProvider;
  isLoading?: boolean;
  onSearch: () => void;
  setSelectedCandidates: (candidates: NewListCandidate[]) => void;
  setFilterTypes: (filterTypes: FilterType[]) => void;
  onFilterStatesUpdate: (fs: ApplicationListingFilters) => void;
  reloadPage: () => void;
  setAlert: (alert: AlertObject) => void;
}

export function ListApplicationsPageBody(props: PropTypes) {
  return (
    <div className='tab-content pb-1' id='candidates-table-tab-content'>
      <div
        className='tab-pane fade show active'
        id='candidates-all'
        role='tabpanel'
        aria-labelledby='candidates-all-tab'
      >
        <div className='app-card app-card-table shadow-sm mb-4'>
          <div className='app-card-body'>
            <ListApplicationsFilters
              filters={props.filterStates}
              filterTypes={props.filterTypes}
              onSearch={props.onSearch}
              setAlert={props.setAlert}
              setFilters={props.onFilterStatesUpdate}
              setFilterTypes={props.setFilterTypes}
            />
            {props.isLoading ? (
              <div className={classNames(styles['loading-spinner-container'])}>
                <LoadingSpinner />
              </div>
            ) : (
              <ListApplicationsTable
                candidates={props.candidates}
                currentUser={props.currentUser}
                emailAccountIntegration={props.emailAccountIntegration}
                error={props.error}
                hrisEmployees={props.hrisEmployees}
                hrisProvider={props.hrisProvider}
                filterStates={props.filterStates}
                totalFilteredCandidates={props.totalFilteredCandidates}
                selectedCandidates={props.selectedCandidates}
                setSelectedCandidates={props.setSelectedCandidates}
                onFilterStatesUpdate={props.onFilterStatesUpdate}
                reloadPage={props.reloadPage}
                setAlert={props.setAlert}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
