import React, { useState } from 'react';
import { ItemPropTypes } from '../../../../components/HorizontalStepper';
import { Candidate } from '../../../../entities/applicant_tracking/Candidate';
import { PageLayout } from './PageLayout';
import { CandidateProfileStep } from './CandidateProfileStep';
import { JobApplicationStep } from './JobApplicationStep';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';

const STEPPER_ITEMS: ItemPropTypes[] = [
  {
    title: 'Personal Information',
    subtitle: 'Add your candidate personal information',
  },
  {
    title: 'Job Application',
    subtitle: 'Assign job information and process',
  },
];

interface PropTypes {
  referrerUserId?: number;
}

export default function CandidateWizard(props: PropTypes) {
  const isReferralFlow = !!props.referrerUserId;
  const [step, setStep] = useState<number>(0);

  const [candidate, setCandidate] = useState<Candidate>(null);
  const [dto, setDto] = useState<CandidateDto>(null);

  function handleSuccess(candidate: Candidate, dto: CandidateDto) {
    setCandidate(candidate);
    setDto(dto);
  }

  return (
    <>
      <PageLayout
        entity={isReferralFlow ? 'Referral' : 'Candidate'}
        stepperItems={STEPPER_ITEMS}
        setupStep={step}
        showBackButton={isReferralFlow}
      >
        <CurrentStep
          step={step}
          onStep={setStep}
          candidate={candidate}
          dto={dto}
          referrerUserId={props.referrerUserId}
          onSuccess={handleSuccess}
        />
      </PageLayout>
    </>
  );
}

function CurrentStep(props: {
  step: number;
  onStep: (n: number) => void;
  candidate: Candidate;
  dto: CandidateDto;
  referrerUserId?: number;
  onSuccess: (c: Candidate, dto: CandidateDto) => void;
}) {
  switch (props.step) {
    case 0:
      return (
        <CandidateProfileStep
          candidate={props.candidate}
          dto={props.dto}
          referralFlow={!!props.referrerUserId}
          onStep={props.onStep}
          onSuccess={props.onSuccess}
        />
      );
    case 1:
      return (
        <JobApplicationStep
          referrerUserId={props.referrerUserId}
          candidate={props.candidate}
          dto={props.dto}
          onStep={props.onStep}
        />
      );
  }
}
