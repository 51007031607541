import React from 'react';
import { Dropdown } from '../../../components/Dropdown';
import { IconSpan } from '../../../components/IconSpan';
import { appendRedirectUri } from '../../../utils/url';

const BUTTON_ENABLED_COLOR_CLASS_NAME = 'text-info';
const BUTTON_DISABLED_COLOR_CLASS_NAME = 'text-muted';

interface PropTypes {
  policies: {
    addCandidateEnabled: boolean;
    addJobEnabled: boolean;
    addReferralEnabled: boolean;
  };
}

function getButtonColor(isEnabled: boolean) {
  return isEnabled
    ? BUTTON_ENABLED_COLOR_CLASS_NAME
    : BUTTON_DISABLED_COLOR_CLASS_NAME;
}

function renderCandidate(addCandidateEnabled: boolean) {
  return {
    action: () => (location.href = `/applicant_tracking/candidate_wizard`),
    buttonChild: (
      <IconSpan
        spanText={'Add Candidate'}
        icon={{ name: 'bi-person-check', className: 'fs-4' }}
        className={getButtonColor(addCandidateEnabled)}
      />
    ),
    isDisabled: !addCandidateEnabled,
  };
}

function renderJob(addJobEnabled: boolean) {
  return {
    action: () => (location.href = '/jobs/setup/job_details'),
    buttonChild: (
      <IconSpan
        spanText={'Add Job'}
        icon={{ name: 'bi-folder-plus', className: 'fs-4' }}
        className={getButtonColor(addJobEnabled)}
      />
    ),
    isDisabled: !addJobEnabled,
  };
}

function renderReferral() {
  return {
    action: () =>
      (location.href = appendRedirectUri(`/applicant_tracking/referrals/new`)),
    buttonChild: (
      <IconSpan
        spanText={'Add Referral'}
        icon={{ name: 'bi-person-plus', className: 'fs-4' }}
        className={BUTTON_ENABLED_COLOR_CLASS_NAME}
      />
    ),
    isDisabled: false,
  };
}

export default function TopBarAddDropdown(props: PropTypes) {
  if (
    !props.policies.addCandidateEnabled &&
    !props.policies.addReferralEnabled &&
    !props.policies.addJobEnabled
  )
    return null;

  const menuActions = [
    renderJob(props.policies.addJobEnabled),
    renderCandidate(props.policies.addCandidateEnabled),
  ];

  if (props.policies.addReferralEnabled) {
    menuActions.push(renderReferral());
  }

  return (
    <Dropdown
      buttonClassName={BUTTON_ENABLED_COLOR_CLASS_NAME}
      buttonText={'Add'}
      buttonIcon={{
        name: 'bi-plus-circle',
      }}
      menuPlacementClass={'dropdown-menu-end'}
      menuActions={menuActions}
    />
  );
}
