import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { ShortApplication } from './ShortApplication';
import React from 'react';
import { InputFormField } from '../../../components/InputFormField';
import { Button } from 'reactstrap';
import { SingleAttachmentInputFormField } from '../../../components/SingleAttachmentInputFormField';
import { Step } from './ShowPage';
import { ApplyAndScheduleLinkSetting } from '../../../entities/v1/applicant_tracking/ApplyAndScheduleLinkSetting';

interface PropTypes {
  register: UseFormRegister<ShortApplication>;
  getValues: UseFormGetValues<ShortApplication>;
  setValue: UseFormSetValue<ShortApplication>;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  isValid: boolean;
  setStep: (n: Step) => void;
  settings: ApplyAndScheduleLinkSetting | null;
}

export function ApplicationDetailForm(props: PropTypes) {
  return (
    <form className='d-flex flex-column gap-3' onSubmit={props.onSubmit}>
      {props.settings?.resume != 'hidden' && (
        <div>
          <SingleAttachmentInputFormField<ShortApplication>
            getValues={props.getValues}
            setValue={props.setValue}
            formRegister={props.register}
            headerClassNames='fw-semibold'
            controllerContentName='resumeContent'
            controllerFileName='resumeFilename'
            fieldName={'Resume / Curriculum Vitae'}
            allowedFormats={['.pdf', '.doc', '.docx']}
            maxFileSizeMb={10}
            isRequired={props.settings?.resume == 'required'}
            disabled={false}
            hideAlert={true}
          />
        </div>
      )}
      {props.settings?.linkedinUrl != 'hidden' && (
        <div>
          <InputFormField
            type='url'
            fieldName='LinkedIn'
            isRequired={props.settings?.linkedinUrl == 'required'}
            placeholder='Insert your LinkedIn'
            classNames='fs-5'
            registerReturn={props.register('linkedinUrl', {
              required: props.settings?.linkedinUrl == 'required',
            })}
          />
        </div>
      )}
      <Button type='submit' color='info' size='lg' disabled={!props.isValid}>
        Continue
      </Button>
      <Button
        type='button'
        color='borderless'
        size='lg'
        onClick={() => props.setStep(1)}
      >
        Previous
      </Button>
    </form>
  );
}
