import React from 'react';
import { Icon } from '../Icon';
import { SpanTooltip } from '../SpanTooltip';

function DefaultToolTipText() {
  return (
    <>
      This candidate&apos;s information<br></br>has been anonymised.
    </>
  );
}

export function AnonymizedTooltip() {
  return (
    <SpanTooltip
      text={
        <Icon
          name='bi-file-earmark-x'
          className='text-blue-gray-900 d-inline-block fs-4'
          style={{ verticalAlign: 'middle' }}
        />
      }
      tooltipText={<DefaultToolTipText />}
      className='ms-2'
    />
  );
}
